$top-offset: 1.25vw;

%nav-switch-color {
	@include respond(nav-switch) {
		color: var(--color-primary-4) !important;
	}
}

.source-link {
	&__container {
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		&:not(.csharp &) {
			background-image: linear-gradient(
				rgba(var(--color-primary-1-rgb), 1),
				rgba(var(--color-primary-1-rgb), .5)
			);
			padding-top: var(--header-height);
			position: relative;
		}
	}

	&__source {
		font-family: $font-family-headers;
		position: fixed;
		z-index: $navbar-full-z-index;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		top: calc(#{$page-nav-top});
		transform: translateY(33%);
		text-decoration: none;
		right: calc((100vw - #{$header-inner-width} - .8vw) / 2);

		@include respond(nav-switch) {
			top: auto;
			transform: translateX(0%);
			right: auto;
			position: static;
		}

		&:nth-of-type(2) {
			order: 2;

			@include respond(nav-switch) {
				order: 0;
			}
		}

		.bridge & {
			right: calc((100vw - #{$header-inner-width}) / 2);
		}

		&-svg {
			width: $navbar__logo-width;
			height: $navbar__logo-width;
			fill: var(--bridge-link-svg-fill);
			opacity: 0.25;
			transition: fill $animation-duration * 0.25 $animation-easing-modern;
			@extend %nav-switch-color;
		}

		&-label {
			text-align: center;
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari 'none' or 'text' here 'all' has issues*/
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
			text-shadow: getTextShadow(soft-emboss, $color-primary-resume-4);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: var(--bridge-link-text-fill);
			font-size: $font-size-four;
			transition: transform $animation-duration * 0.25 $animation-easing-modern;

			border-radius: 50%;
			padding: $default-font-size * 0.25 $default-font-size * 0.5;
			@extend %nav-switch-color;
		}

		&:hover &-label {
			color: var(--bridge-link-text-fill-hover);
			transform: translate(4px, 6px);
			@extend %nav-switch-color;
		}

		&:hover svg {
			fill: var(--bridge-link-svg-fill-hover);
			opacity: .33;
			@extend %nav-switch-color;
		}
	}

	&__demo {
		top: calc(#{$page-nav-top} + #{$navbar__logo-width});
	}

	&__live {
		--multiplier: 2;
		.resume & {
			--multiplier: 1;
		}
		top: calc(#{$page-nav-top} + #{$navbar__logo-width} * var(--multiplier));
	}
}

:is(.csharp, .resume) .source-link__source {
	&-svg {
		fill: var(--color-primary-4) !important;

		&:hover {
			fill: rgba(var(--color-primary-4-rgb), 0.5) !important;
		}
	}

	&-label {
		color: var(--color-primary-4) !important;
	}

	&:hover .source-link__source-label {
		color: var(--color-primary-3) !important;
	}
}