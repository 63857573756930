%svg-behavior {
	transition: opacity $animation-duration $animation-easing-standard;
	cursor: pointer;
	fill: rgba(var(--svg-fill-color), 1);

	&:hover {
		fill: rgba(var(--svg-fill-color), 0.75);
	}

	&:active,
	&:focus {
		fill: rgba(var(--svg-active-color), 1);
	}
}

%hidden {
	transform: translateY(100%);
	transition: transform $animation-duration $animation-easing-standard,
		opacity $animation-duration * 0.5 $animation-easing-standard;
}

.audio-player {
	--background-color: rgba(var(--color-primary-2-rgb), 1);
	--color: var(--color-primary-4);
	--svg-fill-color: var(--color-primary-4-rgb);
	--svg-active-color: var(--color-primary-3-rgb);
	--svg-size: #{$font-size-nine};
	--toggler-padding: #{$default-font-size * 0.5};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	color: var(--color);
	background-color: var(--background-color);
	z-index: 10;
	@extend %hidden;

	&__checkbox-label {
		position: absolute;
		top: 50%;
		right: 0%;
		transform: translate(-50%, -50%);
	}

	&__content {
		max-width: $resume-card-width-full;
		width: 100%;
		text-align: center;
	}

	&__controls {
		display: grid;
		grid-template-rows: 1fr;
		grid-template-columns: repeat(7, 1fr);
		justify-items: center;
		align-items: center;
		padding: $default-font-size 0;

		&
			svg:not(.audio-player__restart, .audio-player__backward, .audio-player__forward) {
			width: var(--svg-size);
			height: var(--svg-size);
		}

		& svg {
			@extend %svg-behavior;
		}

		& > div {
			position: relative;
			width: var(--svg-size);
			height: var(--svg-size);

			& > svg {
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	&__details {
		font-size: $font-size-six;
		padding: $default-font-size * 1;

		&-time {
			margin-left: $default-font-size * 1;
		}
	}

	&__progress {
		cursor: pointer;
		background-color: rgba(var(--svg-fill-color), 0.2);
		width: 100%;
		height: $font-size-six;

		& > div {
			align-self: flex-start;
			background-color: var(--color);
			height: 100%;
		}
	}

	&__backward,
	&__forward {
		width: calc(var(--svg-size) * 1.090838207);
		height: calc(var(--svg-size) * 1.090838207);
	}

	&__toggler {
		position: fixed;
		bottom: 0;
		right: 0;
		height: calc(100% + var(--svg-size) + var(--toggler-padding));
		background-color: var(--background-color);
		z-index: -1;
		border-radius: $default-font-size;

		& svg {
			transition: transform $animation-duration $animation-easing-standard;
			transform: rotate(var(--audio-player-toggler-rotation-closed));
			width: calc(var(--svg-size) * 1.131266846);
			height: calc(var(--svg-size) * 1.131266846);
			@extend %svg-behavior;

			&.audio-player__toggler--open {
				transform: rotate(var(--audio-player-toggler-rotation-open));
			}
		}
	}

	&__restart {
		width: calc(var(--svg-size) * 0.75);
		height: calc(var(--svg-size) * 0.75);
	}
}
