.resume {
	--background-svg-opacity: .985;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin: 0 auto;
	background-image: url($resume-background-1);
	background-size: cover;
	// background-attachment: fixed;
	// @include respond(tab-port) {
	//   margin: $page-margin-top-amount - $default-font-size auto 0 auto;
	// }

	@include respond(nav-switch) {
		background-image: none;
	}

	&__section {
		// background-image: linear-gradient(to left, var(--color-primary-1), var(--color-primary-2));
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// grid-template-columns: 1fr minmax(min-content, 800px) 1fr;
		// grid-auto-rows: minmax(min-content, max-content);
		// border-radius: $default-font-size;

		// row-gap: $default-font-size * 2;
		@include respond(nav-switch) {
			width: 100vw;
		}


		&-overview {
			grid-column: 1 / -1;
			& .resume__card {
				margin-top: 0;
				// background-color: var(--color-primary-3);
				background-image: get-resume-card-gradient(calc(var(--background-svg-opacity) - .025)),
					url($resume-card-background-summary);
				background-attachment: fixed;
				background-size: cover;

				@include respond(tab-port) {
					background-image: var(--color-primary-3);
				}
			}
		}
		&-skills {
			& .resume__card {
				background-position: center;
				background-color: var(--color-primary-3);
				background-image: get-resume-card-gradient(var(--background-svg-opacity)),
					url($resume-card-background-skills);
				background-attachment: fixed;
				background-size: cover;

				@include respond(tab-port) {
					background-image: var(--color-primary-3);
				}
			}
		}
		&-work-history {
			line-height: 1.5;
			& .resume__card {
				background-color: var(--color-primary-1);
				background-image: get-resume-card-gradient(calc(var(--background-svg-opacity) - .02)),
					url($resume-card-background-work-history);
				background-attachment: fixed;
				// background-size: cover;

				@include respond(tab-port) {
					background-image: var(--color-primary-3);
					background-color: var(--color-primary-3);
				}
			}
		}
		&-education {
			& .resume__card {
				background-color: var(--color-primary-3);
				background-image: get-resume-card-gradient(calc(var(--background-svg-opacity) - .025)),
					url($resume-card-background-education);
				background-attachment: fixed;

				@include respond(tab-port) {
					background-image: var(--color-primary-3);
				}
			}
		}
		&-references {
			& .resume__header {
				margin-bottom: $default-font-size * 0;
			}

			& .resume__card {
				background-color: var(--color-primary-3);
				background-image: get-resume-card-gradient(calc(var(--background-svg-opacity) - .025)),
					url($resume-card-background-references);
				background-attachment: fixed;

				@include respond(tab-port) {
					background-image: var(--color-primary-3);
				}
			}

			& .resume__content {
				padding-bottom: $default-font-size / 2;
			}
		}
	}

	&__headers {
		display: flex;
		flex-direction: column-reverse;
	}

	&__header {
		margin-bottom: $default-font-size * 1.5;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-shadow: $resume-header-text-shadow;

		// @include respond(tab-port) {
			// margin-bottom: $default-font-size * 0.75;
		// }

		&:last-child {
			// margin-bottom: $default-font-size * 0.5;
		}

		&-summary {
			grid-column: 1 / -1;
		}
		&-title {
			font-family: $font-family-headers;
		}
	}
	&__hint + &__header {
		margin-bottom: 0;
	}
	&__content {
		padding: $default-font-size * 2.5 $default-font-size $default-font-size * 3.25;
		font-size: $font-size-four;
		font-family: $font-family-text;

		& .bridge__subsection-grid {
			margin-top: $resume-section-padding-top;
		}

		@include respond(phone) {
			padding: $content-padding-nav-switch;
		}

		& .quote {
			margin-top: -$default-font-size;
		}
	}
	&__card {
		max-width: calc(100% - 20.8rem);
		width: $resume-card-width-full;
		margin: $default-font-size * 2 auto;
		color: rgba($color-primary-resume-1, $resume-color-opacity);
		background-color: var(--color-primary-3);
		padding: 0 $default-font-size * 2;
		border-radius: $default-font-size * 0.5;
		box-shadow: $navbar-box-shadow-resume;

		@include respond(nav-switch) {
			max-width: 100%;
		}
		@include respond(tab-land) {
			width: 95%;
		}
		@include respond(tab-port) {
			/* max-width: 95%; */
			width: 100%;
			margin: $default-font-size;
		}
		@include respond(phone) {
			padding: 0;
		}
		@include respond(mobile) {
			margin: $default-font-size * 2 $default-font-size * .5;
		}
	}
	& .heading {
		text-shadow: getTextShadow(
			distant,
			rgba(var(--color-primary-1-rgb), 1),
			var(--color-primary-2-rgb)
		);
		// text-shadow: getTextShadow(soft-emboss, $color-primary-3);
		color: var(--color-primary-3);
	}
	&__hint {
		margin: 0 0 $default-font-size * 2;
		font-size: $font-size-three;

		@include respond(tab-port) {
			margin: -$default-font-size * 0.25 0 $default-font-size * 3;
			height: 0;
		}

		@include respond(phone) {
			// margin-bottom:  $default-font-size * 2;
			margin: -$default-font-size * 0.33 0 $resume-section-padding-top;
		}
		@include respond(small-font) {
			margin-bottom: $default-font-size * 2;
		}
	}

	& .source-link__source {
		&-label {
			border-radius: 50%;
			padding: $default-font-size * 0.25 $default-font-size * 0.5;
			color: rgba(var(--color-primary-4-rgb), 1);
			font-family: $font-family-headers;
			background-color: rgba(var(--color-primary-1-rgb), 0.1);
		}
	}
}

.education {
	&__item {
		margin-left: 1.6rem;
		&:nth-of-type(n + 2) {
			margin-top: $default-font-size * 0.5;
		}

		@include respond(small-font) {
			&:last-of-type {
				margin-bottom: $default-font-size * 0.5;
			}
		}
	}
	&__date,
	&__gpa {
		font-weight: 500;
		font-size: $font-size-four;
	}
	&__date {
		margin-right: $default-font-size * 0.5;
	}
	&__degree {
	}
	&__location {
	}
	&__gpa {
	}
}

.references {
	display: grid;
	grid-template-columns:
		repeat(2, minmax(min-content, $default-font-size * 12.5))
		max-content 1fr;
	grid-auto-rows: minmax($default-font-size * 4, $default-font-size * 6);
	align-items: center;

	& > * {
		padding: $default-font-size * 0.5 $default-font-size * 1.5
			$default-font-size * 0.5 0;
		display: flex;
		align-items: center;
		height: 100%;
		border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);

		&:nth-child(-n + 4) {
			padding-top: 0;
		}

		&:nth-last-child(-n + 4) {
			border-bottom: none;
		}
	}

	

	@include respond(tab-port) {
		grid-gap: 0;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: min-content;

		& > * {
			margin-left: $default-font-size * 3;
			padding: 0;
			border: none;
		}

		& > *:nth-child(4n + 1) {
			margin-left: 0;
		}
	}

	&__name {
		@include respond(tab-port) {
			font-size: $font-size-five;
			margin: $default-font-size 0 $default-font-size * 0.5 0;

			&:nth-child(n + 2) {
				border-top: $default-font-size * 0.1 solid var(--color-primary-1);
			}
		}

		&-link:link,
		&-link:visited {
			@include link-line;
			@include link-animating;
			display: inline;

			&:hover {
				border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);
				border-image: none;
			}
		}

		@include respond(nav-break) {
			&:nth-child(1) {
				margin-top: $default-font-size * 0.5;
			}
		}
	}
	&__relation {
	}
	&__number {
		display: none;
		margin-right: $default-font-size;
		&--no-link {
			border-top: $default-font-size * 0.1875 solid transparent;
		}

		@include respond(tab-port) {
			@include item-number;
			display: inline-block;
		}
	}
	&__email {
		padding-right: 0;
		// display: inline-block;
		@include respond(tab-port) {
			column-count: auto;
			width: auto;
			padding-bottom: $default-font-size * 1;
		}

		@include respond(nav-break) {
			display: inline-block;
			// padding-top: 0;
		}

		& a {
			// @include link-line;
			@include link-animating;
		}
	}

	&__tag {
		display: none;
		margin-right: $default-font-size * 0.5;
		font-weight: 700;

		@include respond(tab-port) {
			display: inline-block;
		}
	}

	@include respond(tab-port) {
		&__email,
		&__relation,
		&__phone,
		&__name {
			grid-column: 1 / -1;
		}
	}
}

div.references__name {
	display: flex;
	align-items: center;
}
