::-webkit-scrollbar-thumb {
	-webkit-border-radius: 0;
	border-radius: 0;
	background-color: $scrollbar-thumb-background;
	-webkit-box-shadow: $scrollbar-box-shadow; 
	box-shadow: $scrollbar-box-shadow;
}

::-webkit-scrollbar {
	width: var(--scrollbar-width);
	height: var(--scrollbar-width);
	background: $scrollbar-thumb-background;
}

::-webkit-scrollbar-corner {
	background: $scrollbar-track-background;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: $scrollbar-box-shadow; 
	box-shadow: $scrollbar-box-shadow;
	background: $scrollbar-track-background;
}