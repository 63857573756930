.skills-popup {
	color: rgba($color-primary-resume-1, $resume-color-opacity);
	position: fixed;
	top: 0;
	left: 0;
	font-size: $font-size-four;
	backdrop-filter: blur(20px) brightness(0.5);
	width: 100vw;
	height: 100vh;
	opacity: 0;
	visibility: hidden;
	z-index: 10000;
	transition: opacity $animation-duration * 1.5 $animation-easing-standard,
		visibility $animation-duration * 1.5 $animation-easing-standard;

	&__content {
		overflow-y: auto;
		overflow-x: hidden;
		width: $default-font-size * 70;
		max-width: 90vw;
		max-height: 90vh;
		height: 100%;
		background-color: var(--color-primary-4);
		border: $default-font-size * 0.15 solid var(--color-primary-1);
		box-shadow: 0 0 $default-font-size * 1.5 0 var(--color-primary-1);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, 200%);
		border-radius: $default-font-size * 0.5;
		transition: transform $animation-duration * 1.5
			$animation-easing-standard-easeInOutBack-smaller;
		
		& > * {
			padding: $default-font-size * 2 0;

			&:nth-of-type(2) {
				padding-top: 0;
				padding-bottom: 0;
			}

			@include respond(nav-switch) {
				padding: 0;
			}
		}

		@include respond(nav-switch) {

			padding: 0;
		}
		@include respond(small-font) {
			max-width: 95vw;
			max-height: 95vh;
		}
	}

	&__header {
		padding: $skills-popup-padding-amount 0 0;
		font-size: $font-size-eight;
		font-weight: 100;
		text-align: center;
		display: grid;
		grid-template-columns: $skill-popup-grid-column-template;
		position: sticky;
		top: 0;
		z-index: $navbar-full-z-index;
		background-color: var(--color-primary-4);
		border-bottom: 1px solid var(--color-primary-1);

		@include respond(nav-switch) {
			border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);
			margin-bottom: $default-font-size * 0.5;
			font-size: $font-size-six;
			padding: $default-font-size;
		}

		&-text {
			grid-column: 1 / -2;
			margin-left: $default-font-size * 3;
		}
	}

	&__table {
		display: grid;
		grid-template-columns: $skill-popup-grid-column-template;

		@include respond(nav-switch) {
			display: flex;
			flex-direction: column;
		}

		&-repo {
			position: relative;
			grid-column: 1/-1;
			padding-right: $default-font-size * 3;

			&:nth-last-child(n + 2) {
				border-bottom: $default-font-size * 0.1 solid
					var(--color-primary-1-light);
			}

			@include respond(nav-switch) {
				padding-right: 0;
			}
		}

		&-headers {
			display: flex;
			grid-column: 1 / -1;
			justify-content: flex-start;
		}

		&-header {
			display: grid;

			&:nth-last-child(1) {
				padding-right: 0;
				justify-self: center;
			}

			// & *:nth-last-child(2) {
			// 	padding-right: $default-font-size * 1.25;
			// }
			// & *:nth-last-child(3) {
			// 	padding-right: $default-font-size * 1;
			// }
		}

		&-header {
			font-size: $font-size-four;
			font-weight: 700;
			display: flex;
			align-items: center;
			text-align: center;

			&:nth-of-type(1) {
				padding-left: $skills-popup-padding-amount;
			}

			&:nth-last-of-type(1) {
				padding-right: $skills-popup-padding-amount;
			}
		}
		&-item {
			border-bottom: 1px solid var(--color-primary-1-light);
			font-weight: 400;
			font-family: $font-family-text;
			display: flex;
			align-items: center;
			transition: border $animation-duration * 0.5 $animation-easing-standard;

			@include respond(nav-switch) {
				border: none;
				padding: $default-font-size;
				margin-left: $default-font-size;

				&:nth-child(n + 4) {
					padding-top: 0;
				}

				&:nth-child(3) {
					display: grid;
					grid-template-columns: max-content 1fr;
					column-gap: $default-font-size;
				}

				.skills-popup__table-repo > &:first-child {
					font-size: $font-size-four;
					display: flex;
					column-gap: $default-font-size;
				}

				.skills-popup__table-repo > &:last-child {
					position: absolute;
					top: $default-font-size * 1;
					right: $default-font-size * 1;
					padding-right: 0;
				}

				& .skills-popup__table-item {
					padding: 0;
				}
			}

			& span {
				border-bottom: $default-font-size * 0.1 solid transparent;
				font-style: normal;

				@include respond(nav-switch) {
					&:nth-child(2n + 1) {
						font-weight: 700;
					}
				}
			}

			&.skills-popup__description {
				display: inline-block;
				& a {
					text-decoration: none;
					&:hover {
						border-image: none;
					}
				}

				@include respond(nav-switch) {
					padding: 0;
				}
			}

			// &:nth-child(5n + 3),
			// &:nth-child(5n + 4) {
			// 	padding-right: 0
			// }

			&:nth-child(5n + 1) {
				padding-left: $skills-popup-padding-amount;
				word-break: break-word;

				@include respond(nav-switch) {
					padding-left: 0;
				}
			}

			&:nth-child(5n + 5)  {
				padding-right: $skills-popup-padding-amount;
			}

			&:nth-last-child(-n + 5) {
				border-bottom: 0;
			}
		}

		&-item, &-header {
			padding: $default-font-size * 1.5 $default-font-size * 2
				$default-font-size * 1.5 0;
		}
	}

	&__url {
		margin-left: 0;
		backface-visibility: hidden;
		display: flex;
		justify-content: center;
		& svg {
			fill: var(--color-primary-1);
			height: $default-font-size * 2;
			width: $default-font-size * 2;

			transition: color $animation-duration * 0.5 $animation-easing-standard,
				transform $animation-duration * 0.5 $animation-easing-standard;
		}
		&:hover svg {
			fill: var(--color-primary-2);
			transform: scale(1.15);
		}
	}

	&__hint {
		grid-column: 1 / -1;
		font-size: $font-size-three;
		font-style: italic;

		@include respond(nav-switch) {
			padding-bottom: $default-font-size;
		}
	}

	&__close {
		margin-right: $skills-popup-padding-amount;
		cursor: pointer;
		fill: var(--color-primary-1);
		height: $default-font-size * 2;
		width: $default-font-size * 2;
		align-self: center;
		margin-left: auto;
		backface-visibility: hidden;
		transition: fill $animation-duration * 0.25 $animation-easing-standard,
			transform $animation-duration * 0.25 $animation-easing-standard;
		&:hover {
			fill: var(--color-primary-2);
			transform: scale($scale-amount * 1.1);
		}

		@include respond(nav-switch) {
			margin-right: 0;
		}
	}

	&__link {
		cursor: pointer;
		text-decoration: none;
		position: relative;

		&-text {
			@include link-line;
			&::before {
				border-radius: 100%;
				background-image: linear-gradient(
					to bottom,
					var(--color-primary-1) 0%,
					var(--color-primary-4) 5%,
					var(--color-primary-4) 95%,
					var(--color-primary-4) 100%
				);
				content: "";
				position: absolute;
				top: 50%;
				left: 0%;
				opacity: 0;
				width: 1px;
				height: $default-font-size * 4;
				// max-height: calc(100% - .8rem);
				transform: translate(0%, -50%);
				z-index: -1;
				transition: opacity $animation-duration * 0.25
						cubic-bezier(1, 0, 0.25, 0.55) $animation-duration * 0.25,
					height $animation-duration * 0.25 $animation-easing-modern
						$animation-duration * 0.25,
					width $animation-duration * 0.25 $animation-easing-modern,
					border-radius $animation-duration * 0.25 $animation-easing-standard
						$animation-duration * 0.25;
			}
		}

		&:hover &-text {
			color: var(--color-primary-1);
			border-image: none;
			border-image-slice: 1;
		}
	}

	&__error {
		grid-column: 1 /-1;
		justify-self: center;
		font-size: $font-size-seven;
		padding: 0 $default-font-size * 2;
		position: absolute;
		text-align: center;
		top: 50%;
	}

	&--active {
		opacity: 1;
		visibility: visible;

		& .skills-popup__content {
			transform: translate(-50%, -50%);
		}
	}

	//This has to go at the end to override a previous style
	&__table-item.skills-popup__link {
		border-bottom: none;
	}

	&__content::-webkit-scrollbar {
		width: $default-font-size;
	}

	&__content::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&__content::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
}
