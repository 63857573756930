.work-history {
	&__item {
		display: grid;
		grid-template-columns: min-content 1fr max-content;
		font-size: $font-size-four;
		padding: $default-font-size * 2 0;
		column-gap: $default-font-size / 2;
		
		@include respond(tab-port) {
			grid-template-columns: min-content 1fr;
			padding-bottom: $default-font-size * 1;
			padding-top: $default-font-size * 1;
		}

		&:nth-of-type(1) {
			padding-top: 0;
			margin-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

		&:nth-child(n + 2) {
			border-top: $default-font-size * 0.1 solid var(--color-primary-1);
		}
		// grid-template-columns: min-content minmax(min-content, 1fr);
		// grid-gap: $default-font-size;
		&-section {
			grid-column: 2/-1;

			& h6 {
				margin-top: $default-font-size * 1;

				@include respond(tab-port) {
					margin-top: $default-font-size * 0.5;
				}
			}
			&-list {
				// margin-left: $default-font-size * 1;
				// margin-top: -$default-font-size * .25;
				line-height: $default-font-size * 1.5;
				margin: -$default-font-size * 0.5 $default-font-size * 3 0rem
					$default-font-size;

				@include respond(tab-port) {
					margin: -$default-font-size * 0.5 $default-font-size * 1.5 0
						$default-font-size * 0.5;
				}

				& li {
					margin-left: $default-font-size * 1.5;
					font-size: $font-size-four;
				}

				& li:first-child {
					margin-top: $default-font-size * 0.5;
				}
			}
		}

		&-number {
			@include item-number;
		}
	}

	&__title {
		&-header {
			font-size: $font-size-five;
		}

		&-location {
			font-size: $font-size-three;
			grid-column: 2/-1;
			font-weight: 500;
			padding-bottom: $default-font-size * 1.5;
		}

		&-dates {
			padding-bottom: $default-font-size * 1.5;
			font-size: $font-size-three;
			display: flex;
			align-items: flex-start;
			font-weight: 400;
			grid-column: 3 / -1;
			grid-row: 1/-1;
			font-style: italic;

			& > div:nth-child(2) {
				margin: 0 $default-font-size * 0.25;
			}

			@include respond(nav-break) {
				margin-top: .5rem;
			}
		}
	}
}
