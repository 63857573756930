$thumbnail-transition: 
		transform $animation-duration * 0.66 $animation-easing-standard $animation-duration,
;

.thumbnail {
	box-shadow: $navbar-box-shadow-resume;
	background-color: var(--color-primary-1);
	width: $default-font-size * 3;
	height: $default-font-size * 3;
	overflow: hidden;
	border-radius: 50%;
	border: $default-font-size * 0.1 solid var(--color-primary-4);
	transition: $thumbnail-transition;

	& img {
		width: 100%;
		transition: $thumbnail-transition;
		z-index: 10000;
	}

	&:hover {
		animation: black-hole-background 1 2s;
		background-color: $color-black;
	}
	&:hover > img {
		animation: black-hole 1 2s;
		transform: translateY(-150%);
		filter: saturate(1);
	}
}
