$scale-y-amount: .25;
.navbar {
	width: var(--site-nav-button-width);
	--site-nav-button-width: 7.5vw;

	// margin-left: 0.583vw;
	border-radius: $navbar__item-border-radius 0 0 $navbar__item-border-radius;
	position: relative;
	font-size: $font-size-six;
	text-align: center;
	display: flex;
	grid-template-columns: min-content repeat(
		4,
		1fr,
	);
	align-items: center;

	@include respond(nav-switch) {
		--site-nav-button-width: auto;
		align-items: flex-start;
		border-radius: 0;
		&.overflow--hidden.navbar.navbar--active {
			overflow: visible !important;
		}
	}

	&__background {
		position: fixed;
		top: 0;
		left: 0;
		width: 200vw;
		height: 200vh;
		visibility: hidden;
		z-index: -1;
	}

	&__menu {
		width: $navbar__logo-width;
		height: $navbar__logo-width;
		position: relative;

		@include respond(nav-switch) {
			width: $navbar__logo-width-nav-switch;
			height: $navbar__logo-width-nav-switch;
		}

		&-bar,
		&-bar::before,
		&-bar::after {
			background-color: var(--color-primary-1);
			height: $navbar__logo-width * 0.1;
			width: $navbar__logo-width * 0.5;

			@include respond(nav-switch) {
				height: $default-font-size * 0.5;
				width: $default-font-size * 2.5;
			}
		}

		&-bar {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			&,
			&::before,
			&::after {
				transition: transform $animation-duration * 0.5
						$animation-easing-standard,
					width $animation-duration * 0.5 $animation-easing-standard;
			}

			&::before,
			&::after {
				content: "";
				position: absolute;
				transform: translate(-50%, -50%);
				left: 50%;
			}

			&::before {
				transform: translate(-50%, calc((#{-$navbar__logo-width * .1}) - 50%));
			}

			&::after {
				transform: translate(-50%, calc((#{$navbar__logo-width * .1}) + 50%));
			}
		}

		//Spread Menu bars on hover
		&:hover &-bar::before {
			transform: translate(-50%, calc((-#{$navbar__logo-width} * .15) - 50%));
		}
		&:hover &-bar::after {
			transform: translate(-50%, calc((#{$navbar__logo-width} * .15) + 50%));
		}

		//Change menu bars to 'A' on click
		.navbar.navbar--active:not(.closed) &-bar::before {
			transform:  $navbar__button-bar-before-rotation
				translate($navbar__logo-width * .053, $navbar__logo-width * .38);
			@include respond(nav-switch) {
				transform: $navbar__button-bar-before-rotation
				translate($default-font-size * 0.2857, $default-font-size * 2);
			}
		}
		.navbar.navbar--active:not(.closed) &-bar {
			width: calc(#{$navbar__logo_width} * .25);
		}
		.navbar.navbar--active:not(.closed) &-bar::after {
			transform:  $navbar__button-bar-after-rotation
				translate($navbar__logo-width * 0, $navbar__logo-width * -.1);
			@include respond(nav-switch) {
				transform: $navbar__button-bar-after-rotation
					translate(-$default-font-size * 0.075, -$default-font-size * 0.5);
			}
		}
	}

	&__content {
		position: relative;
		z-index: 10;
		visibility: hidden;
		width: 100%;
		transform-origin: left;
		transform: translateX(-100%);
		transition: visibility $animation-navbar, transform $animation-navbar;

		@include respond(nav-switch) {
			transform: translateY(0);
			position: absolute;
			transform-origin: top;
			top: calc(#{$header-height} - #{$default-font-size * 1});
			left: -1px;
			display: flex;
			overflow: visible !important;
			flex-direction: column;
			width: $header-width-nav-switch;
		}
		// @include respond(tab-land) {
		// 	width: $header-width-tab-land;
		// }
		// @include respond(phone) {
		// 	width: $header-width-phone;
		// }
		// @include respond(small-font) {
		// 	width: $header-width-small-font;
		// }
	}

	&__list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		list-style: none;
		backface-visibility: hidden;
		transform-origin: top left;
		transition: transform $animation-duration * 0.5 $animation-easing-standard,
			opacity $animation-duration * 0.75 $animation-easing-standard,
			width $animation-duration * 0.5 $animation-easing-modern
				$animation-duration * 0.5;
		// transition:
		// 	transform $animation-duration * .5 $animation-easing-modern,
		// 	opacity $animation-duration * 1 $animation-easing-standard,
		// 	width $animation-duration * .25 $animation-easing-standard $animation-duration * .125,
		// ;

		--min-column-width: #{$default-font-size * 16.8125};
		@include respond(nav-switch) {
			display: grid;
			grid-template-columns: repeat(
				auto-fill,
				minmax(var(--min-column-width), 1fr)
			);
			column-gap: 1px;
			row-gap: 1px;
			flex-direction: column;
			opacity: 0;
			/* this add the width part of the animation */
			/* width: $default-font-size * 1; */
			transform: scale(0);
		}

		@include respond(nav-list-break) {
			--min-column-width: #{$default-font-size * 8};
		}

		& .navbar__item:first-child {
			/* border-radius: $default-font-size * 0.5 0 0 $default-font-size * 0.5; */
			border-radius: 0;
			@include respond(nav-switch) {
			}
		}

		& .navbar__item:nth-child(n + 1) {
			@include respond(nav-switch) {
			}
		}

		& > .navbar__item:nth-of-type(3) {
			@include respond(nav-switch) {
				order: 1;
				border-radius: 0 0 $default-font-size * 1 $default-font-size * 1;
				z-index: 10;
			}
		}

		& .navbar__dropdown .navbar__item {
			margin-left: 0;
		}
	}

	&__item {
		border-left: $navbar__item-divider-width solid var(--color-primary-1);
		position: relative;
		background-color: var(--color-primary-4);
		// background: linear-gradient(
		// 	var(--site-nav-item-gradient-direction),
		// 	var(--color-primary-4),
		// 	var(--color-primary-3),
		// );
		cursor: pointer;
		height: 100%;
		width: 100%;
		transition: transform $animation-duration * 0.25 $animation-easing-standard,
			background-color $animation-duration * 0.25 $animation-easing-standard;
		
		& .triangle-down {
			transition: transform $animation-duration * 0.5 $animation-easing-standard;
		}

		&:hover {
			// background-color: var(--color-primary-3);
			// background: linear-gradient(
			// 	var(--site-nav-item-gradient-direction),
			// 	var(--color-primary-3),
			// 	var(--color-primary-4),
			// );
			transition: background-image $animation-duration $animation-easing-modern;

			& > .navbar__link-image {
				opacity: $bg-video-opacity * 3;
			}
		}
		&:not(.navbar__dropdown-container):hover {
			z-index: 10000;
			transform: translateY(-$navbar__item-divider-width);
			background: rgba(var(--color-primary-1-rgb), $resume-color-opacity);
			box-shadow: $navbar-box-shadow-hover;
			transition: background $animation-duration / 3 $animation-easing-standard,
				transform $animation-duration / 2 $animation-easing-standard,
				box-shadow $animation-duration / 2 $animation-easing-standard;

			& a {
				color: rgba(var(--color-primary-4-rgb), 0.95);
				transition: color $animation-duration / 2 $animation-easing-standard;
			}

			& + * {
				border-top: $navbar__item-divider-width solid transparent !important;
				@include respond(nav-switch) {
					border-top: 1px solid var(--color-primary-1) !important;
				}
			}
		}
		&:not(.navbar__dropdown-container) img,
		.navbar__link > img {
			pointer-events: none;
			width: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
		}
		.navbar__link > img {
			height: 100%;
		}
		&:hover .triangle-down {
			transform: rotate(90deg);
		}
		&:last-child,
		&:first-child {
			border-radius: 0 10rem 10rem 0;
			@include respond(nav-switch) {
				border-radius: 0;
			}
		}
	}

	div.navbar__link {
		cursor: auto;
	}

	&__link,
	&__link:link,
	&__link:visited {
		padding: $default-font-size * $paddingFactor 0;
		height: 100%;
		width: 100%;
		text-decoration: none;
		color: var(--color-primary-1);
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 300;

		transition: padding $animation-duration * 1 $animation-easing-standard,
			opacity $animation-duration * 0.5 $animation-easing-standard
				$animation-duration * 0.5;

		@include respond(nav-switch) {
			flex-direction: column;
			position: relative;
			z-index: 10000;
			opacity: 0;
		}
	}

	&--done &__item {
		@include respond(nav-switch) {
			border: 1px solid var(--color-primary-1);
		}
	}

	&__button {
		border-style: double;
		border: 1px solid var(--color-primary-1);
		cursor: pointer;
		z-index: 100;
		border: 1px solid transparent;
		background-color: var(--color-primary-4);
		border-radius: $navbar__item-border-radius;
		display: flex;
		align-items: center;

		transition: border-radius $animation-duration * 1.5 $animation-easing-standard;
		@include respond(nav-switch) {
			border-radius: $default-font-size;
			border: none;
			transition: border-radius $animation-duration * 0.5
				$animation-easing-standard;
		}

		&:hover {
			background-color: var(--color-primary-3);
		}
	}

	&__dropdown {
		z-index: -100000;
		position: absolute;
		top: 100%;
		left: 0%;
		width: 100%;
		height: auto;
		list-style: none;
		display: flex;
		flex-direction: column;
		transform-origin: bottom;
		transform: translateY(-100%) scaleY(1);
		transition: transform $animation-duration / 4 ease-in;

		@include respond(mobile) {
			visibility: visible;
			opacity: 1;
			height: auto;
			transform: translateY(0%) scale(1);
		}

		@include respond(nav-switch) {
			position: relative;
			height: 0;
			transform: translateY(0%) scale(0);
			top: 0;
		}

		&-group {
			display: flex;
			align-items: center;
		}

		& .navbar__item {
			opacity: 0;
			border: none;
			transform-origin: top;
			transition: 
				padding $animation-duration $animation-easing-standard,
				opacity $animation-duration $animation-easing-standard,
				transform $animation-duration * 0.25 $animation-easing-standard,
				background-color $animation-duration * 0.25 $animation-easing-standard;

			@include respond(nav-switch) {
				border-top: $default-font-size * 0.1 solid transparent;
				border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);
				border-left: $default-font-size * 0.1 solid var(--color-primary-1);
				border-right: $default-font-size * 0.1 solid var(--color-primary-1);
				margin-top: 0;
				width: 75%;
				align-self: center;
			}

			&:first-child {
				border-radius: 0;
				// margin-top: $navbar__item-margin-top;
				@include respond(nav-switch) {
					border-top: $default-font-size * 0.1 solid var(--color-primary-1);
					margin-top: $default-font-size * 0.75;
				}
			}
			&:last-child {
				border-radius: 0 0 $navbar__item-border-radius / 10
					$navbar__item-border-radius / 10;
				@include respond(nav-switch) {
					border-radius: 0 0 $default-font-size * 0.5 $default-font-size * 0.5;
				}
			}

			&:hover {
				transform: translateX($navbar__item-divider-width * 2) !important;
				box-shadow: $navbar-box-shadow-left-right-hover;
			}

			@include respond(nav-switch) {
				&:hover,
				&:hover + li {
					border-top: $default-font-size * 0.1 solid var(--color-primary-1);
				}
			}
		}

		& .navbar__link:link,
		& .navbar__link:visited {
			padding: 0;
		}
	}

	&__dropdown-container {
		// transition: height $animation-duration / 10 $animation-easing-standard;
		grid-column: 1 / -1;

		&:hover {
			transform: translate(0);
		}
	}

	//Showing dropdown when dropdown-container or any dropdown item is hovered
	&__dropdown-container:hover &__dropdown,
	&__dropdown &__item:hover &__dropdown {
		@include site-nav-examples-expanded;
		/* background: var(--color-primary-4);
		border-radius: 0 0 $default-font-size $default-font-size; */
	}

	//expanding SiteNav 'examples' menu if mobile device
	&__dropdown-container &__dropdown,
	&__dropdown &__item &__dropdown,
	&__dropdown &__item {
		@include respond(mobile) {
			@include site-nav-examples-expanded;
		}
	}

	//Styling dropdown items on dropdown-container hover
	&__dropdown-container:hover &__dropdown &__item {
		--color: var(--color-primary-1);
		--size: #{$navbar__item-divider-width};
		height: 100%;
		opacity: 1;
		border-top: var(--size) solid var(--color);
		
		&:hover {
			--color: transparent;
		}

		@include respond(nav-switch) {
			width: 90%;
			max-width: $default-font-size * 30;
			--size: 0px;

			&:first-child{
				--size: 1px;
			}
		}
	}

	&__dropdown-container:hover &__dropdown &__link {
		padding: $default-font-size * $paddingFactor $default-font-size * 2;
	}

	//setting navbar button width to auto to allow for expansion
	&.navbar--isAnimating,
	&.navbar--active {
		--site-nav-button-width: auto;
	}

	//when navbar is open
	&.navbar--active {
		& .navbar__link,
		& .navbar__link:link,
		& .navbar__link:visited {
			opacity: 1;
		}
		& .navbar__list {
			opacity: 1;
			width: 100%;
			transform: scale(1);
		}
		& .navbar__content {
			visibility: visible;
			opacity: 1;
			transform: scaleX(1) scaleY(1);

			@include respond(nav-switch) {
				width: $header-width-nav-switch;
				padding-right: $default-font-size / 2;
				/* width: 100vw; */
				position: absolute;
				z-index: 100;

			
			}

			@include respond(mobile) {
				width: calc(100vw - (#{$default-font-size} / 2));
				padding-right: 0;
			}

			// @include respond(tab-land) {
			// 	width: $header-width-tab-land;
			// }

			// @include respond(phone) {
			// 	width: $header-width-phone;
			// }

			// @include respond(small-font) {
			// 	width: $header-width-small-font;
			// }
		}

		&:not(.closed) .navbar__button {
			border-radius: $default-font-size * 10 $default-font-size * 0
				$default-font-size * 0 $default-font-size * 10;
			@include respond(nav-switch) {
				border-radius: $default-font-size * 1 $default-font-size * 1
					$default-font-size * 0 $default-font-size * 0;
			}
			//A hover styling
			&:hover .navbar__menu-bar::before {
				transform: rotate(135deg) translate($navbar__logo-width * .17, $navbar__logo-width * .17);
				@include respond(nav-switch) {
					transform: rotate(135deg) translate($default-font-size * .85, $default-font-size * .85);
				}
			}
			&:hover .navbar__menu-bar::after {
				transform: rotate(-135deg) translate($navbar__logo-width * .17, -$navbar__logo-width * .17);
				@include respond(nav-switch) {
					transform: rotate(-135deg) translate($default-font-size * .85, -$default-font-size * .85);
				}
			}
			&:hover .navbar__menu-bar {
				width: 0;
			}
		}

		& .navbar__link:not(&__dropdown &__link) {
			padding: $default-font-size * $paddingFactor $default-font-size * $paddingFactor * 1.5;

			@include respond(nav-switch) {
				padding: $default-font-size 0;
			}
		}

		&:not(.closed) .navbar__background {
			visibility: visible;
			transition: filter $animation-duration $animation-easing-standard;

			backdrop-filter: blur($default-font-size * 0.5) brightness(0.5)
				drop-shadow(
					0px $default-font-size * 1 $default-font-size * 2 var(--color-grey-80)
				);
		}
	}

	//Handling Bug where leaving button after clicking causes overflow hidden to change
	&.navbar--isAnimating {
		& .navbar__content {
			transform: scaleX(1) translateX(-100%) scaleY(1);

			@include respond(nav-switch) {
				transform: scaleX(1) translateY(-$default-font-size * .25) scaleY(1);
			}
		}

		//fixes UI bug where clicking a dropdown link to change pages leave a visual remnant while closing
		& .navbar__dropdown {
			display: none;
		}

		* {
			pointer-events: none;
			box-shadow: none;
		}
	}
	& * {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: text; /* Safari 'none' or 'text' here 'all' has issues*/
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
	}
}

.closed {
	& .navbar__list {
		opacity: 0 !important;
		visibility: hidden;
		transition: opacity $animation-duration ease;
		pointer-events: none;

	}

	& .navbar__button {
		border-radius: 100% !important;
		transition: border-radius $animation-duration ease !important;
	}
}

.open {
	& .navbar__list {
		opacity: 1 !important;
		pointer-events: all;
		transition: opacity $animation-duration ease;
	}
	& .navbar__button {
		transition: border-radius $animation-duration ease !important;
	}
}

#root {
	overflow: hidden;
}

.header {
	pointer-events: none;
	top: $header-top;
	width: 100%;
	position: fixed;
	z-index: 1;
	transition: $header-transition;

	@include respond(nav-switch) {
		padding: $default-font-size * 0.5;
		box-shadow: $navbar-box-shadow-resume;
		top: 0;
		z-index: 10;
		background-image: var(--body-background);
		border-bottom: $default-font-size * 0.1 solid var(--color-primary-4);
	}

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: $header-inner-width;
		margin: 0 auto;

		& * {
			pointer-events: all;
		}

		@include respond(nav-switch) {
			width: var(--header-inner-width-nav-switch);
		}
	}

	&-toggler {
		display: none;
		box-shadow: $navbar-box-shadow-resume;
		height: calc(var(--header-height) + #{$header-toggler-width});
		border-left: $default-font-size * 0.15 solid var(--color-primary-4);
		border-bottom: $default-font-size * 0.15 solid var(--color-primary-4);
		border-right: $default-font-size * 0.15 solid var(--color-primary-4);
		border-radius: 0 0 1000px 1000px;
		position: fixed;
		right: 0;
		top: 0;
		background-color: var(--color-primary-1);
		opacity: 0;
		transition: height $animation-duration * 1 ease;

		@include respond(nav-switch) {
			opacity: 1;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			&__svg {
				cursor: pointer;
				transform: rotate(var(--header-toggler-svg-rotation))
					translate(
						var(--header-toggler-translate-x),
						var(--header-toggler-translate-y)
					);
				fill: var(--color-primary-4);
				width: calc(#{$header-toggler-width} * .88085);
				height: $header-toggler-width;
				transition: transform $animation-duration $animation-easing-standard,
					all $animation-duration $animation-easing-standard;
			}

			&--active {
				& svg {
					--header-toggler-svg-rotation: 0deg;
				}

				& + .header {
					transform: translateY(-100%);
				}
			}
			&:hover {
				background-color: var(--color-primary-4);
				svg {
					fill: var(--color-primary-1);
				}
			}
		}

		@include respond(tab-land) {
			right: $default-font-size * .5;
		}
	}
}

.page-nav {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari 'none' or 'text' here 'all' has issues*/
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
	z-index: 1;
	position: absolute;
	top: $page-nav-top;
	left: 0; 
	width: calc((100% - #{$resume-card-width-full}) / 2);
	font-size: $font-size-six;
	display: grid;
	justify-items: center;
	align-items: center;
	grid-auto-rows: min-content;
	max-height: calc(100vh - $page-nav-top);
	transition: opacity $animation-duration * 2.5 $animation-easing-standard;
	overflow-y: auto;
	padding-bottom: $default-font-size * 2;

	@include respond(nav-switch) {
		z-index: 0;
		font-size: $font-size-five;
		position: static;
		height: auto;
		width: calc(var(--header-inner-width-nav-switch) - 16rem);
		grid-template-columns: repeat(
			auto-fill,
			minmax(var(--page-nav-min-column-width), 1fr)
		);
		justify-items: center;
		padding-bottom: 0;
		// margin-left: $default-font-size * 0.25;
	}

	&__section {
		backface-visibility: hidden;
		cursor: pointer;
		width: 100%;
		text-align: center;
		text-decoration: none;
		list-style: decimal;
		color: transparent;
		background-image: var(--site-nav-linear-gradient);
		-webkit-background-clip: text;
		background-clip: text;
		padding: .5vw 0;
		transition: color $animation-duration $animation-easing-standard;

		@include respond(nav-switch) {
			padding: $default-font-size * 0.25 0;
		}

		@include respond(small-font) {
			padding: 0;
		}

		@include respond(mobile) {
			&:hover {
				transform: scale(1.25);
			}
		}

		&-group {
			font-size: min(#{$font-size-four}, 1vw);
			padding: $default-font-size * 0.25 $default-font-size * 2;
			display: flex;
			justify-content: center;
			align-items: center;
			backface-visibility: hidden;
			list-style: none;
			transition: transform $animation-duration * 0.25
				$animation-easing-standard;

			&:nth-of-type(1) {
				// padding-top: 0;
				@include respond(nav-switch) {
					margin-left: 0;
					// padding-top: $default-font-size * 0.25 * 2;
				}
			}

			&:last-of-type {
				@include respond(nav-switch) {
					// padding-bottom: 0;
				}
			}

			&:hover {
				transform: translateY(-0.25vw);

				& span {
					text-shadow: getTextShadow(soft-emboss, var(--color-primary-4-rgb));
					background-image: linear-gradient(
						to right,
						rgba(var(--color-primary-4-rgb), 1),
						rgba(var(--color-primary-4-rgb), 1)
					) !important;
				}

				@include respond(nav-switch) {
					transform: translateY(-$default-font-size * 0.1);
				}
			}

			&:hover > a {
				@include respond(hover) {
					color: var(--color-primary-2);
				}
			}

			@include respond(nav-switch) {
				font-size: min(#{$font-size-four}, 1.5vw);
			}
		}

		&:hover {
			text-shadow: getTextShadow(soft-emboss, var(--color-black-rgb));
		}
	}

	&--active {
		transform: scale(var(--site-nav-active-scale-amount));
		// animation: selected-section infinite 5s forwards;
	}
}

.page-nav- {
	display: none;
}
