.audio-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($default-font-size * 24, 1fr));

  &__item {
    --padding-top-and-bottom: #{$default-font-size * .5};
    --padding-right: #{$default-font-size * 1};
		// margin-left: $default-font-size * 2;
		@include link-animating;
		@include link-line;

		text-decoration: underline;
    width: max-content;
    padding: var(--padding-top-and-bottom) var(--padding-right) var(--padding-top-and-bottom) 0;

    @include respond($breakpoint-to-change-layout) {
      --padding-top-and-bottom: #{$default-font-size} * .25;
      --padding-right: #{$default-font-size} * .5;
      width: auto;
    }

    & > span:nth-of-type(2) {
      padding-left: $default-font-size * .5;
    }

		&:hover {
			@include link-hover;
		}
	}
}