
:root{
  --diamond-clip-low: 31.8%;
  --diamond-clip-high: 68.2%;
}

.diamond-fractal {
	opacity: .0;
  background: var(--color-primary-1);
	
  // height: 100%;
  // width: 100%;
  // position: relative;

  clip-path: polygon(
    0 0,
    100% 0%,
    var(--diamond-clip-high) var(--diamond-clip-low),
    var(--diamond-clip-high) var(--diamond-clip-high),
    100% 100%,
    0 100%,
    var(--diamond-clip-low) var(--diamond-clip-high),
    var(--diamond-clip-low) var(--diamond-clip-low),
    0 0,
  ); 

	position: fixed;
	top: 0;
	left: 20%;
	bottom: 0;
	right: 0;
	transition:
		left $animation-duration + $bridge-hero-animation-duration / 2 $animation-easing-standard-easeInOutBack ,
		opacity $animation-duration + $bridge-hero-animation-duration / 2 $animation-easing-standard-easeInOutBack ,
		;
}

.square {
  opacity: .5;
  // opacity: calc(var(--diamond-outer-opacity) * var(--square-root-of-two));

  mix-blend-mode: difference;
	// opacity: var(--diamond-outer-opacity);
	background-color: var(--color-primary-2);

	position: absolute;
	height: var(--diamond-fractal-length);
	width: var(--diamond-fractal-length);
	max-height: 100vh;
	max-width: 100vw;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: var(--translate-center) rotate(var(--diamond-fractal-rotation));

	// &__text {
  //   mix-blend-mode: difference;

	// 	z-index: 10000;
	// 	opacity: 1;
	// 	// position: absolute;
	// 	color: var(--color-primary-2);

  //   &--top-left {
  //     font-size: $default-font-size * 8;
  //     // top: 0%;
  //     // left: 0%;
  //     // transform: translate(-42%, -75%);
  //   }
	// }

	&__inner {
		&-odd {
			// opacity: calc(var(--diamond-outer-opacity) * var(--square-root-of-two));
			transform: var(--translate-center) rotate(0);
			// background-color: var(--color-primary-4);
		}
		&-even {
			// animation: square-even var(--diamond-fractal-animation-duration) infinite linear;
			// opacity: calc(var(--diamond-outer-opacity) / 2);
			background-color: var(--color-primary-2);
		}

    &-1 {
      // mix-blend-mode: color;
      // background-color: var(--color-primary-3);
      animation: square-odd var(--diamond-fractal-animation-duration) infinite
        linear;
      height: calc(var(--diamond-fractal-length) * var(--square-root-of-two));
      width: calc(var(--diamond-fractal-length) * var(--square-root-of-two));
    }
    &-2 {
      animation: square-even var(--diamond-fractal-animation-duration) infinite
        linear;
  
      height: calc(var(--diamond-fractal-length) / 2);
      width: calc(var(--diamond-fractal-length) / 2);
    }
  
    &-3 {
      animation: square-odd calc(var(--diamond-fractal-animation-duration) / 2)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 2 * var(--square-root-of-two));
      width: calc(var(--diamond-fractal-length) / 2 * var(--square-root-of-two));
    }
    &-4 {
      animation: square-even calc(var(--diamond-fractal-animation-duration) / 2)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 4);
      width: calc(var(--diamond-fractal-length) / 4);
    }
    &-5 {
      animation: square-even calc(var(--diamond-fractal-animation-duration) / 4)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 4 * var(--square-root-of-two));
      width: calc(var(--diamond-fractal-length) / 4 * var(--square-root-of-two));
    }
    &-6 {
      animation: square-even calc(var(--diamond-fractal-animation-duration) / 8)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 8);
      width: calc(var(--diamond-fractal-length) / 8);
    }
    &-7 {
      animation: square-even calc(var(--diamond-fractal-animation-duration) / 8)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 8 * var(--square-root-of-two));
      width: calc(var(--diamond-fractal-length) / 8 * var(--square-root-of-two));
    }
    &-8 {
      animation: square-even calc(var(--diamond-fractal-animation-duration) / 16)
        infinite linear;
      height: calc(var(--diamond-fractal-length) / 16);
      width: calc(var(--diamond-fractal-length) / 16);
    }
	}
	&__outer {
		background: var(--color-primary-bridge-1);
		height: calc(var(--diamond-fractal-length) * 1);
		width: calc(var(--diamond-fractal-length) * 1);
	}
	
}

.circle {

	position: absolute;
	top: 50%;
	left: 50%;
	transform: var(--translate-center);
	background-color: var(--color-primary-4);
	// border-radius: $default-font-size * 2;

	&-1 {
		width: calc(var(--diamond-fractal-length));
		height: calc(var(--diamond-fractal-length));
	}
	&-2 {
		width: calc(var(--diamond-fractal-length) * var(--square-root-of-two));
		height: calc(var(--diamond-fractal-length) * var(--square-root-of-two));
	}
	&-3 {
		width: calc(var(--diamond-fractal-length) / 2);
		height: calc(var(--diamond-fractal-length) / 2);
	}
	&-4 {
		width: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 2);
		height: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 2);
	}
	&-5 {
		width: calc(var(--diamond-fractal-length) / 4);
		height: calc(var(--diamond-fractal-length) / 4);
	}
	&-6 {
		width: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 4);
		height: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 4);
	}
	&-7 {
		width: calc(var(--diamond-fractal-length) / 8);
		height: calc(var(--diamond-fractal-length) / 8);
	}
	&-8 {
		width: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 8);
		height: calc(var(--diamond-fractal-length) * var(--square-root-of-two) / 8);
	}
	&-9 {
		width: calc(var(--diamond-fractal-length) / 16);
		height: calc(var(--diamond-fractal-length) / 16);
	}

}
