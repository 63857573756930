.card {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: text; /* Safari 'none' or 'text' here 'all' has issues*/
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

	cursor: pointer;
	z-index: $card-z-index;
	// backface-visibility: hidden;
	color: var(--color-primary-1);
	position: relative;
	// height: $card-height;
	// width: $card-height * 2 / 3;
	display: grid;
	grid-template-rows: $card-header-height 1fr;
	align-items: center;
	justify-items: center;
	transform: scale(var(--card-scale-amount));
	transition: transform $animation-duration / 3 $animation-easing-standard;

	@include respond(nav-switch) {
		margin: .25vw 0;
	}

	& * {
		// backface-visibility: hidden;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
	}

	&__progress {
		cursor: pointer;
		opacity: 0;
		width: 100%;
		height: 3px;
		position: absolute;
		bottom: -$default-font-size * 0.2;
		left: 0;
		/* Reset the default appearance */
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: none;

		&::-webkit-progress-bar {
			background-color: var(--color-primary-4);
			border-radius: 1px;
			box-shadow: 0 2px 5px rgba($color-primary-bridge-2, 0.25) inset;
		}

		&::-webkit-progress-value {
			background-image: -webkit-linear-gradient(
					-45deg,
					transparent 33%,
					rgba($color-primary-bridge-1, 0.1) 33%,
					rgba($color-primary-bridge-1, 0.1) 66%,
					transparent 66%
				),
				-webkit-linear-gradient(top, rgba($color-primary-bridge-4, 0.25), rgba($color-primary-bridge-1, 0.25)),
				-webkit-linear-gradient(left, var(--color-primary-1), var(--color-primary-2));

			border-radius: 2px;
			background-size: 35px 20px, 100% 100%, 100% 100%;
		}
	}

	&__checkbox {
		display: none;
	}

	&__children {
		opacity: 0;
		padding: $default-font-size / 4;
		padding-right: $default-font-size / 1.33;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: $default-font-size * 0.25;
		background-color: rgba(var(--color-primary-4-rgb), $resume-color-opacity);
		border: 0.1px solid var(--color-primary-1);
		width: 75%;
		max-width: 75%;
		display: flex;
		align-items: center;
		transition: opacity $animation-duration * 2 $animation-easing-standard,
			transform $animation-duration $animation-easing-standard-easeInOutBack;

		@include respond(nav-break) {
			// font-size: $default-font-size * 0.3125;
		}

		& ul {
			list-style: decimal;
			font-size: $default-font-size * 0.2;
		}
		
		& *:not(li, &-close, p) {
			padding-left: $default-font-size * 0.2;
		}

		&-toggler {
			// position: absolute;
			// left: 0;
			// top: 50%;
			// transform: translate(-50%, -50%) rotate(-90deg);
			// width: $default-font-size;
			// transform: translate(100%, -50%);
		}

		&-close {
			height: $default-font-size / 2;
			width: $default-font-size / 2;
			pointer-events: all;
			cursor: pointer;
			position: absolute;
			top: .2rem;
			right: .2rem;
			fill: var(--color-primary-2);
			opacity: 1;
		}
	}

	&__image {
		width: 100%;
		position: absolute;
		// opacity: .3;
		z-index: -1;
		transition: filter $animation-duration * 0.5 $animation-easing-standard;
		filter: sepia(0.25) saturate(0.66) blur(0.7px)
			drop-shadow(
				0px $card-image-drop-shadow-x $card-image-drop-shadow-y
					rgba(var(--color-black-rgb), $card-image-drop-shadow-opacity)
			);

		.card:hover & {
			filter: sepia(0) saturate(1) blur(0px)
				drop-shadow(
					0px calc(#{$card-image-drop-shadow-x} * var(--card-scale-amount))
						calc(#{$card-image-drop-shadow-y} * var(--card-scale-amount))
						rgba(var(--color-black-rgb), calc(#{$card-image-drop-shadow-opacity} * var(--card-scale-amount)))
				);
		}
	}
	&__title {
		grid-column: 1/-1;
		// backface-visibility: hidden;
		text-align: center;
		font-size: $font-size-five;
		padding: $default-font-size * 0.25;
		height: 100%;
		width: 100%;
		// padding: $default-font-size;
	}
	&__text {
	}

	&__content {
		// width: 85%;
		// display: grid;
		// grid-template-columns: 80% 20%;
	}

	&__play,
	&__pause,
	&__close,
	&__stop,
	&__restart {
		cursor: pointer;
		opacity: 0;
		// visibility: hidden;
		top: $card-pause-and-stop-top;
		left: 50%;
		transform: translate(-50%, -50%);
		height: $card-pause-and-stop-width;
		width: $card-pause-and-stop-width;
		fill: rgba($color-primary-bridge-2, 1);
		z-index: 10;
		position: absolute;
		transition: opacity $animation-duration $animation-easing-standard,
			fill $animation-duration $animation-easing-standard;
	}

	&__play {
		top: 50%;
	}

	&__play-parent,
	&__pause-parent,
	&__close-parent,
	&__stop-parent,
	&__restart-parent {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		height: 50%;
		width: 50%;
		position: absolute;
	}

	&
		:is(.card__pause, .card__stop, .card__restart, .card__play, .card__close):is(:active, :focus) {
		fill: var(--color-primary-4);
	}

	&
		:is(.card__stop-parent, .card__pause-parent, .card__restart-parent, .card__play-parent, .card__close-parent):is(:active, :focus)
		svg {
		visibility: visible;
		fill: var(--color-primary-4);
		transition: opacity $animation-duration $animation-easing-standard;
	}

	&__play {
		width: 100%;
		height: 100%;
	}

	&__pause {
		left: 20%;
		width: $card-pause-and-stop-width;
		height: $card-pause-and-stop-width;
	}

	&__stop {
		left: 40%;
		width: $card-pause-and-stop-width;
		height: $card-pause-and-stop-width;
	}

	&__close {
		left: 80%;
	}

	&__restart {
		left: 60%;
		width: $card-pause-and-stop-width * 0.75;
		height: $card-pause-and-stop-width * 0.75;
	}

	&__current-svg {
		z-index: 10;
		opacity: $card-initial-opacity;
	}

	&--hoverable {
		&:hover {
			--card-scale-amount: 1.5;
			z-index: $card-z-index + 1;
		}
		
		&:hover .card__children {
			z-index: $card-z-index;
		}

		&:not(.card--done, .card--open):hover .card__play {
			opacity: $card-initial-opacity * 2;
			// visibility: visible;
			fill: rgba($color-primary-bridge-1, 1);
		}

		&:is(.card--open .card__content, .card--done .card__content):hover
			.card__close {
			opacity: $bridge-card-hover-button-opacity;
			// visibility: visible;
		}
	}

	&--open {
		transform: var(--card-playing-transform);
		// width: calc(#{$resume-card-width-full} - var(--scrollbar-width));
		z-index: 2;
		grid-template-rows: auto;
		cursor: auto;

		& .card__progress {
			opacity: 1;
		}

		&
			:is(.card__play-parent, .card__pause-parent, .card__close-parent, .card__stop-parent, .card__restart-parent) {
			height: auto;
			width: auto;
			position: static;
			top: auto;
			left: auto;
			transform: none;
		}

		& .card__content {
			// height: 67%;
			height: 100%;
			// width: 94.4%;
		}

		& .card__image {
			width: 102%;
		}

		& .card__title {
			font-size: $font-size-one;
			padding: 0;
			display: none;
		}
		& .fg-video {
			opacity: 1;
			// visibility: visible;
			grid-column: 1/-1;
			position: relative;

			& video {
				object-fit: contain;
			}
		}

		& .card__current-svg {
			opacity: 0;
			visibility: hidden;
		}

		& .card__play {
			opacity: 0;
			visibility: hidden;
		}

		& .card__stop,
		& .card__pause {
			// opacity: $card-initial-opacity * 2;
			fill: var(--color-primary-2);
		}

		& :is(.card__close, .card__pause, .card__stop, .card__restart):hover {
			opacity: 1 !important;
			// visibility: visible;
		}

		&
			.card__content:hover
			:is(.card__pause, .card__stop, .card__restart, .card__close) {
			opacity: $bridge-card-hover-button-opacity;
			// visibility: visible;
		}

		// &:hover .card__stop {
		// 	opacity: $card-initial-opacity * 2;
		// }
	}

	&--done {
		&.card--open {
			grid-template-rows: auto;
		}

		& .card__close {
			z-index: $card-z-index + 10;

			&:hover {
				opacity: 1 !important;
				// visibility: visible;
			}
		}

		& .card__image {
			width: 102%;
		}

		& .card__children {
			@include card-description-visible;
		}

		& :is(.card__pause, .card__stop) {
			opacity: 0;
			visibility: hidden;
		}

		&:hover :is(.card__pause, .card__stop) {
			opacity: 0;
			// visibility: hidden;
		}
	}

	&--stopped {
		& .card__children {
			@include card-description-visible;
		}

		& .card__play {
			top: $card-pause-and-stop-top;
			left: 20%;
			width: $card-pause-and-stop-width;
			height: $card-pause-and-stop-width;
			visibility: visible;
		}

		& .card__content:hover :is(.card__play) {
			opacity: $bridge-card-hover-button-opacity;
			visibility: visible;
		}

		& .card__content:hover :is(.card__pause) {
			opacity: 0 !important;
			// visibility: hidden;
		}

		& .card__play:hover {
			opacity: 1 !important;
			// visibility: visible;
		}
	}

	&--stopped .card__close,
	&--closed .card__close {
		animation: color-change 6s infinite, bridge-more 2s infinite;

		&:hover {
			animation: none;
			transition: transform $animation-duration $animation-easing-standard;
		}
	}

	// &:first-of-type {
	// 	transform-origin: top left;
	// }
	// &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4) {
	// 	transform-origin: top ;
	// }
	// &:nth-of-type(5) {
	// 	transform-origin: top right;
	// }
	// &:nth-of-type(6) {
	// 	transform-origin: bottom left;
	// }
	// &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9) {
	// 	transform-origin: bottom ;
	// }
	// &:last-of-type{
	// 	transform-origin: bottom right;
	// }
}
