.skills {
	font-size: $font-size-four;
	font-family: $font-family-text;
	// list-style: none;
	position: relative;

	&__section {
		list-style: none;

		&:nth-child(3) .skills__title {
			cursor: auto;
			border: $default-font-size * 0.1 solid transparent;
		}

		&-content {
			height: 0;
			visibility: hidden;
			opacity: 0;
			transition: opacity $animation-duration $animation-easing-standard;

			font-weight: 500;
			display: grid;
			grid-template-columns: max-content 1fr;
			row-gap: $default-font-size * 0.125;
			column-gap: $default-font-size * 1;
			grid-auto-rows: min-content;
			align-items: center;
			margin-left: $default-font-size;

			& .skills__section {
				grid-column: 1/-1;
			}

			@include respond(nav-break) {
				grid-template-columns: 1fr;
			}
		}
		&-title {
			backface-visibility: hidden;
			margin: 0 0 $resume-section-padding-top / 2 0;
			user-select: none;
			cursor: pointer;
			display: flex;
			align-items: center;
			border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);
			grid-column: 1/-1;
			font-size: $font-size-five;
			font-weight: 300;

			transition: transform $animation-duration * 0.5 $animation-easing-standard,
				color $animation-duration * 1 $animation-easing-standard;

			@include respond(small-font) {
				margin-bottom: $default-font-size * 0.75;
			}

			&-svg {
				pointer-events: none;
				backface-visibility: hidden;

				fill: var(--color-primary-1);
				height: $default-font-size * 1.5;
				width: $default-font-size * 1.5;
				// margin-left: $default-font-size * .25;
				transition: fill $animation-duration * 1 $animation-easing-standard,
					transform $animation-duration * 0.25 $animation-easing-standard;
			}

			&:hover {
				transform: translateX($default-font-size * 0.5);
				font-weight: 500;
				color: var(--color-primary-2);
			}

			&.skills__section--open:hover {
				// color: var(--color-primary-2);
			}

			&:not(.skills__section--open)
				+ .skills__section-content
				.skills__section-content {
				visibility: hidden;
				display: none;
			}

			&:hover &-svg {
				fill: var(--color-primary-1);
			}
		}

		&-svg {
			margin-right: $default-font-size * 0.5;
			width: $default-font-size * 0.5;
			height: $default-font-size * 0.5;
			fill: var(--color-primary-1);
		}

		//Click Title Open Animation
		&--open + &-content {
			height: auto;
			visibility: visible;
			opacity: 1;
			margin-bottom: $default-font-size * 1.5;
		}

		&--open svg {
			transform: rotate(90deg);
		}

		//Click Title Width Animation
		// &--open + &-content .percent-bar__outer {
		//   width: 100%;
		// }
		&--open + &-content .percent-bar__inner {
			opacity: 1;
		}

		& > *:nth-child(2n + 2) {
			margin-left: $default-font-size;
		}

		&-content &:last-of-type &-content {
			margin-bottom: 0;
		}
	}	

	&__item {
		// list-style: none;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		max-width: $default-font-size * 50;
		// margin-left: $default-font-size * 1;

		@include respond(nav-break) {
			margin: 0 0 $default-font-size * 0.25 0;
		}
	}

	&__title:link,
	&__title:visited,
	&__title,
	&__section &__section .skills__title {
		cursor: pointer;
		backface-visibility: hidden;
		position: relative;
		display: inline-block;
		text-decoration: none;
		color: var(--color-primary-1);
		border-bottom: $default-font-size * 0.1 solid var(--color-primary-1);

		&:hover{
			color: rgba(var(--color-primary-1-rgb), .5);
		}
	}

	&__title--animating,
	&__title--animating:link,
	&__title--animating:visited,
	&__section &__section .skills__title {
		@include link-animating;
		transition: border $animation-duration * 0.5 $animation-easing-standard,
			transform $animation-duration $animation-easing-standard;

		&:link:hover,
		&:visited:hover,
		&:hover {
			@include link-hover;
		}
	}

	&-popup__link.skills__title--animating:link,
	&-popup__link.skills__title--animating:visited {
		display: flex;
	}

	&__title--animating.skills__section--open {
		animation: none;
		transform: translateX($default-font-size);
		font-weight: 700;
		max-width: calc(100% - 1.6rem);
	}


	& > &__section:last-of-type &__section-content {
		margin-bottom: 0;
	} 
}
