%item-filter {
	transition: filter $animation-duration * 0.5 $animation-easing-standard;
	filter: brightness(0.85);
	cursor: pointer;


	.carousel__item--full-screen & {
		cursor: auto;
		filter: brightness(1);
	}

	@include respond(mobile) {
		filter: brightness(1);
	}
}

.carousel {
	position: relative;
	grid-column: 1 / -1;
	display: grid;
	column-gap: $carousel-column-gap;
	row-gap: $carousel-column-gap;
	grid-template-columns: repeat(4, 1fr);
	justify-items: flex-start;
	align-items: flex-start;
	overflow: hidden;
	margin: 0 auto;
	width: calc(100% - #{$default-font-size} * 3.75);
	padding: $default-font-size * 2 0;
	justify-items: center;
	align-items: center;

	@include respond(nav-switch) {
		margin-left: 0;
		justify-content: center;
		column-gap: $default-font-size / 2;
		// grid-template-columns: repeat(
		// 	auto-fill,
		// 	minmax($default-font-size * 5, $carousel-item-card-size-nav-switch)
		// ) !important;
		row-gap: $default-font-size * 0.5;
	}

	@include respond(tab-land) {
		width: 100%;
	}

	@include respond(small-font) {
		grid-template-columns: repeat(
			auto-fill,
			minmax($default-font-size * 5, $carousel-item-card-size-nav-switch * .9)
		) !important;
	}

	&__dots {
		grid-column: 3 / span 1;
		grid-row: 2 / span 1;
		display: flex;
		justify-content: center;
		align-items: center;

		@include respond(nav-switch) {
			display: none;
		}
	}

	&__dot {
		--opacity: 0.33;
		position: relative;
		z-index: 6;
		cursor: pointer;
		width: $navbar__logo-width / 2;
		height: $navbar__logo-width / 2;
		fill: rgba(
			#{var(--color-primary-1-rgb)},
			var(--carousel-dot-alpha, var(--opacity))
		);

		&:hover {
			--carousel-dot-alpha: 0.75;
		}

		&--active {
			--opacity: 1;
		}
	}

	&__arrow-button {
		--fullscreen-top: calc(#{$carousel-full-screen-media-height} / 2);
		--translation-y: 0%;
		display: none;
		position: relative;
		z-index: 100;
		// transform: none;
		justify-self: center;
		align-self: center;
		cursor: pointer;

		@include respond(nav-switch) {
			display: none;
		}

		&--full-screen {
			display: inline;
			--translation-y: -50%;
			position: fixed;
			z-index: 1000000;
			// top: var(--fullscreen-top);
			display: block;
			top: calc(#{$carousel-full-screen-item-height} / 2);
			opacity: .75;
			bottom: auto;
			
			@include respond(nav-switch) {
				top: auto;
				bottom: $carousel-nav-switch-button-bottom;
			}

			&:hover {
				opacity: 1;
			}
		}

		&--full-screen#{&}--left {
			left: $default-font-size;
		}

		&--full-screen#{&}--right {
			right: $default-font-size;
		}

		&--left {
			--rotation: 90deg;
			grid-column: 2 / span 1;
			grid-row: 2 / span 1;
			transform: translateY(var(--translation-y)) rotate(var(--rotation));
		}

		&--right {
			--rotation: -90deg;
			grid-column: 4 / span 1;
			grid-row: 2 / span 1;
			transform: translateY(var(--translation-y)) rotate(var(--rotation));

			
		}

		@include respond(nav-switch) {
			&--right, &--left {
				--translation-y: 0;
			}
		}

		& svg {
			width: calc(#{$carousel-svg-parent-width} * 0.75);
			height: calc(#{$carousel-svg-parent-width} * 0.75);
			fill: rgba(var(--color-primary-1-rgb), 0.75);

			@include respond(nav-switch) {
				width: $carousel-svg-parent-width-nav-switch;
				height: $carousel-svg-parent-width-nav-switch;
			}

			&:hover,
			&:active,
			&:focus {
				fill: var(--color-primary-1);
			}
		}
	}

	&__item {
		pointer-events: none;
		position: relative;
		z-index: 1;
		font-size: $font-size-four;
		width: 95%;
    	// margin: 0 auto $default-font-size auto;
		height: $default-font-size * 10.921;
		border-radius: $default-font-size / 2;
		overflow: hidden;
		transition: 
			border-radius $animation-duration $animation-easing-standard,
			box-shadow $animation-duration $animation-easing-standard,
			transform $animation-duration $animation-easing-standard;

		@include respond(nav-switch) {
			height: $carousel-item-card-size-nav-switch;
		}

		&:hover {
			border-radius: 4px;
			box-shadow: 0px 4px 8px rgba(var(--color-black-rgb), 0.5);
			transform: translateY(-.5rem) !important;
		}

		& * {
			pointer-events: all;
		}

		&-description {
			--background-opacity: #{$resume-color-opacity};
			--font-size: #{$font-size-three};
			--padding: #{$default-font-size * 0.5};
			--padding-top: var(--padding);
			--padding-right: var(--padding);
			--padding-left: var(--padding);
			pointer-events: none;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: var(--font-size);
			font-weight: 700;
			text-align: center;
			left: 0;
			bottom: 0;
			width: 100%;
			// height: 33%;
			min-height: 33%;
			position: absolute;
			color: var(--color-primary-4);
			z-index: 10000;
			background: rgba(var(--color-primary-1-rgb), var(--background-opacity));
			padding: var(--padding-top) var(--padding-right) var(--padding-top)
				var(--padding-left);

			// visibility: hidden;
			// opacity: 0;
			// transition: opacity $animation-duration $animation-easing-standard;

			.carousel__item--full-screen & {
				--background-opacity: 1;
				--font-size: #{$font-size-seven};
				--padding-top: calc(var(--padding) * 2);
				--padding-right: #{$carousel-svg-parent-width};
				--padding-left: calc(#{$carousel-svg-parent-width} * 2);
				transition: none;
				height: calc(100vh - #{$carousel-full-screen-item-height});
				min-height: 0;
				// background-color: var(--color-black);

				@include respond(nav-switch) {
					align-items: flex-start;
					--padding-top: calc(#{$carousel-svg-parent-width} * 0.066);
					--padding-right: var(--padding);
					--padding-left: var(--padding);
				}

				@include respond(small-font) {
					--font-size: #{$font-size-five};
				}
			}

			.carousel__item--full-screen img ~ & {
				align-items: center;
				padding: $default-font-size;
			}
		}

		& .card__progress {
			z-index: 100000;
			bottom: calc(100vh - #{$carousel-full-screen-item-height});
			opacity: 0;
			visibility: hidden;
			height: $carousel-progress-bar-height;
			position: fixed;
			// cursor: pointer;
			// width: 100%;
			// left: 0;
			// -webkit-appearance: none;
			// appearance: none;
			// border: none;
		}

		&--full-screen {
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 100000;
			transform: translate(0, 0) !important;
			transition: none;
			border: none;
			border-radius: 0;
			background-color: rgba(var(--color-black-rgb), 1);

			& .card__progress {
				opacity: 1;
				visibility: visible;

				&::-webkit-progress-bar {
					background-color: var(--color-primary-1);
					box-shadow: 0 3px 16px rgba(0, 0, 0, 0.25);
					border: 1px solid var(--color-primary-4);
				}
			}

			& .carousel__video {
				cursor: auto;
			}

			& .carousel__video ~ .item-description {
				padding: $default-font-size * 1
					calc(#{$carousel-svg-parent-width} + #{$default-font-size})
					$default-font-size * 1 calc(#{$carousel-svg-parent-width} * 2);
			}

			& .item-description {
				display: flex;
				font-size: $font-size-seven;
				justify-content: center;
				align-items: center;
				position: fixed;
				min-height: calc(100vh - #{$carousel-full-screen-item-height});
				background: var(--color-primary-4);
				color: var(--color-primary-1);

				@include respond(nav-switch) {
					font-size: $font-size-six;
				}
			}

			&:hover {
				transform: translate(0, 0) !important;
			}
		}
	}

	&__video {
		background-color: rgb(var(--color-white-rgb));
		&.fg-video {
			@extend %item-filter;
			opacity: 1;
			video {
				top: 0;
				left: 50%;
				position: fixed;
				height: 100%;
				// object-position: left;
				object-fit: contain;
				transform: translateX(-50%);
				width: auto;

				.carousel__item--full-screen & {
					width: 100vw;
					backdrop-filter: blur(10px);
					height: $carousel-full-screen-media-height;
				}
			}

			&.full-screen {
				video {
					height: $carousel-full-screen-media-height;
				}
			}
		}

		&-svg {
			pointer-events: none;
			width: $carousel-svg-width;
			height: $carousel-svg-width;
			fill: var(--color-primary-4);
			z-index: 100000;
			position: absolute;
			top: 46%;
			left: 56%;
			transform: translate(-50%, -50%);
			transition: fill $animation-duration / 4 $animation-easing-standard;

			& use {
				pointer-events: none;
			}

			.carousel__item--full-screen & {
				opacity: 0;
			}
		}

		&:hover ~ &-svg {
			fill: var(--color-primary-3);
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top;

		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;

		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: text; /* Safari 'none' or 'text' here 'all' has issues*/
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

		.carousel__item--full-screen & {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -0%);
			width: auto;
			height: $carousel-full-screen-item-height;

			@include respond(nav-switch) {
				width: 100%;
				height: 74vh;
			}
		}

		@extend %item-filter;

		@include respond(nav-switch) {
			width: 100%;
		}
	}

	&__item:hover &__item-description,
	&__item:active &__item-description,
	&__item:focus &__item-description,
	&__item--full-screen &__item-description {
		visibility: visible;
		opacity: 1;
	}

	//overlay-text styling
	& .overlay-text {
		--color: var(--color-primary-4-rgb);
		--background-color: var(--color-primary-1-rgb);
		top: calc(#{$carousel-full-screen-media-height} / 2) !important;
		left: 50% !important;
		opacity: 0;
		visibility: hidden;
		transition: opacity $animation-duration $animation-easing-standard;
		padding: $default-font-size $default-font-size * 2;
		color: rgba(var(--color), 1);
		background-color: rgba(var(--background-color), 1);
		border: 1px solid rgba(var(--color), 1);
		border-radius: $default-font-size / 2;
		box-shadow: 0px $default-font-size / 2 $default-font-size $default-font-size * 0.5 rgba(var(--color-black-rgb), 0.5);
		max-height: 85%;
		max-width: 85%;
		margin: 0 auto;
		overflow: auto;

		@include respond(phone) {
			left: 0 !important;
			right: 0 !important;
			transform: translate(0, -50%) !important;
		}

		&-title {
			font-size: $font-size-seven;
		}

		& .bridge__link {
			@include link-animating(
				var(--color),
				var(--background-color),
				var(--color),
				var(--color),
			);

			@include link-line(
				var(--background-color),
				var(--color),
				var(--color),
				var(--background-color),
			);

			&:hover {
				color: rgba(var(--color-white-rgb), 1);
			}
		}
	}
	&__item--stopped .overlay-text,
	&__item--done .overlay-text {
		opacity: 1;
		visibility: visible;
	}

	//control styling below here
	& :is(.card__play, .card__pause, .card__restart, .card__stop, .card__close) {
		opacity: 1;
		visibility: visible;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
	}

	&
		:is(.card__play-parent, .card__pause-parent, .card__restart-parent, .card__stop-parent, .card__close-parent) {
		--width: #{$carousel-svg-parent-width};
		cursor: pointer;
		z-index: -1;
		width: var(--width);
		height: var(--width);
		top: auto;
		bottom: 0;
		transform: translateY(0);

		@include respond(nav-switch) {
			--width: #{$carousel-svg-parent-width-nav-switch};
		}

		& svg {
			fill: rgba(var(--color-primary-red-rgb), 1);
			&:hover {
				fill: rgba(
					var(--color-primary-red-rgb),
					$carousel-fullscreen-button-hover-opacity
				);
			}
		}
	}

	& .card__play-parent {
		left: 0;
		visibility: visible;
		opacity: 1;
		@include respond(nav-switch) {
			left: $carousel-svg-parent-offset-nav-switch;
			transform: translateX(-50%);
		}
	}

	& .card__pause-parent {
		left: 0;
		opacity: 0;
		visibility: hidden;
		@include respond(nav-switch) {
			left: $carousel-svg-parent-offset-nav-switch;
			transform: translateX(-50%);
		}
	}

	& .card__restart-parent {
		right: $default-font-size * 1.5;
		left: auto;

		@include respond(nav-switch) {
			right: calc(50% - #{$carousel-svg-parent-width-nav-switch});
			transform: translateX(50%);
		}
	}

	& .card__stop-parent {
		left: $carousel-svg-parent-width;

		@include respond(nav-switch) {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	& .card__close-parent {
		right: 0;
		left: auto;
		top: 0;
		transform: none;
		// opacity: calc(#{$carousel-fullscreen-button-hover-opacity} / 2);

		@include respond(nav-switch) {
			top: auto;
			right: auto;
			left: 50%;
			bottom: $carousel-nav-switch-button-bottom;
			transform: translateX(-50%);
		}

		// &:hover {
		// 	opacity: calc(#{$carousel-fullscreen-button-hover-opacity});
		// }
	}

	& .card__play {
	}

	& .card__pause {
	}

	& .card__restart {
		width: 75%;
		height: 75%;
	}

	& .card__stop {
	}

	& .card__close {
		// transform: translate(5%, 80%);
		// left: auto;
		// right: $default-font-size * 1.375;
		// right: 2.2rem;
		// top: 1.6rem;
		// top: $default-font-size;
		// bottom: 10vh;
		--width: #{$carousel-svg-parent-width};
		height: $default-font-size * 4;
		width: $default-font-size * 4;
		opacity: 0;

		@include respond(nav-switch) {
			--width: #{$carousel-svg-parent-width-nav-switch};
		}
	}

	//showing close x when hovering
	& .card__close-parent:hover .card__close {
		opacity: 1;
	}

	//making close button more noticable
	&__item--stopped ~ .card__close-parent .card__close,
	&__item--done ~ .card__close-parent .card__close {
		animation: 5s infinite color-change;
	}

	//hiding pause when video is stopped
	:is(.carousel__item--stopped, .carousel__item--done) ~ .card__pause-parent {
		display: none;
	}

	&__item--playing {
		& ~ .card__play-parent {
			opacity: 0;
			visibility: hidden;
		}
		& ~ .card__pause-parent {
			opacity: 1;
			visibility: visible;
		}
	}
	// :is(&__item--stopped, &__item--done) ~ .card__pause-parent {
	// 	visibility: visible;
	// 	opacity: 1;
	// }

	//showing controls when is open
	&
		&__item--full-screen
		:is(.card__play-parent, .card__pause-parent, .card__restart-parent, .card__stop-parent, .card__close-parent) {
		z-index: 10000;

		&:not(.card__close-parent) {
			@include respond(nav-switch) {
				bottom: calc(0.016 * (100vh - #{$carousel-full-screen-item-height}));
			}

			&:hover svg {
				fill: var(--color-primary-4);
			}

			&:active svg,
			&:focus svg {
				fill: var(--color-primary-3);
			}
		}
	}

	&__item--full-screen .card__close-parent .card__close {
		opacity: 1;
	}
}

.overlay-text-title {
	&-container {
		display: flex;
    	align-items: center;
		flex-direction: row;
		justify-content: space-between;
	}
	&-close {
		cursor: pointer;
		width: $font-size-seven;
		height: $font-size-seven;
		fill: var(--color-primary-2);

		&:hover {
			fill: var(--color-primary-3);
		}
	}
}