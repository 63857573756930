%name-font {
	font-family: $font-family-fancy-bridge;
	font-weight: $home-name-font-weight;
}

.home {
	position: relative;
	height: 100vh;
	width: 100vw;
	font-size: $font-size-five;
	font-weight: 400;
	text-shadow: getTextShadow(
		close,
		var(--color-black-rgb),
		var(--color-black-rgb)
	);

	@include respond(nav-switch) {
		// font-size: $default-font-size;
	}

	& > * {
		z-index: 1;
	}

	//this sets styles specific to home page like body to hide overflow for home page
	&-page {
		overflow-y: hidden;

		& .header {
			width: 100vw;
		}

		& .page-nav {
			display: none;
		}
	}

	// background-image: linear-gradient(
	// 		to right,
	// 		var(--color-primary-3) 0%,
	// 		var(--color-primary-3) 50%,
	// 		var(--color-primary-3) 50%
	// 	),
	// 	url("imgs/background-2.jpg");
	// background-position: center;
	// background-size: cover;
	// background-blend-mode: color-burn;

	// &__portrait {
	//   position: absolute;
	//   bottom: 0%;
	//   left: 50%;
	//   transform: translate(-50%, 0%);
	//   width: 100%;
	//   height: 100%;

	//   background-image:
	//   //   linear-gradient(to right,
	//   //   var(--color-primary-1) 0%,
	//   //   var(--color-primary-2) 50%,
	//   //   var(--color-primary-3) 50%,
	//   // ),
	//   url('imgs/self-small.png');
	//   filter: saturate(0%);
	//   background-position: center;
	//   background-size: cover;
	//   background-blend-mode: saturation;
	//   // & img {
	//   //   width: 100%;
	//   //   filter: saturate(0);
	//   // }
	// }

	&__canvas {
		position: absolute;
		left: 0;
		top: 0;
		width: 100vw !important;
		height: 100vh !important;
		z-index: -1;
	}

	&__name {
		color: var(--color-primary-3);
		opacity: 0;
		visibility: hidden;
		font-size: $font-size-eleven;
		position: absolute;
		transform: translate(-50%, -50%);
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
		align-items: flex-end;
		width: max-content;
		left: 50%;
		top: 15%;
		transition: opacity $animation-duration * 2 $animation-easing-standard;

		@include respond(phone) {
			top: 16%;
			font-size: 11vw;
		}

		@include respond(small-font) {
			top: 18.5%;
			// font-size: $default-font-size * 3;
		}
	}

	&__name-first {
		@extend %name-font;
		justify-self: flex-end;
		// transform: translate(-$default-font-size * 50, 0);
	}

	&__name-last {
		@extend %name-font;
		justify-self: flex-start;
		// transform: translate($default-font-size * 50, 0);
	}

	&__third-word {
		font-weight: 900;
		grid-column: 1/-1;
		// transform: translate(0, 100vh);
		font-size: $font-size-six;
	}

	&__main {
		animation: ocean-sky-fading 100s forwards $animation-easing-standard
			infinite;
		// font-size: $default-font-size;
		line-height: 1.7;
		// font-family: $font-family-text;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: grid;
		justify-content: center;
		--home-column-width: calc(
			((100vw - #{$header-inner-width-vw}) + #{$navbar__logo-width})
		);
		grid-template-columns: repeat(1, var(--home-column-width)) 1fr repeat(
				1,
				var(--home-column-width)
			);
		grid-template-rows: 1fr min-content 1fr min-content;

		@include respond($breakpoint-to-change-layout) {
			--home-column-height: 33vh;
			grid-template-columns: 1fr 1fr;

			grid-template-rows:
				repeat(2, var(--home-column-height))
				max-content
				0fr;

				line-height: 1.4;
			
		}

		@include respond(phone-touch) {
		}
		// &-welcome {
		// 	--welcome-opacity: .75;
		// 	color: rgba(var(--color-primary-4-rgb), var(--welcome-opacity));
		// 	grid-column: 1 / -1;
		// 	grid-row: 3 / -1;
		// 	padding: 0 $default-font-size;
		// 	display: grid;
		// 	grid-template-rows: min-content 1fr min-content;
		// 	align-items: center;
		// 	justify-items: center;
		// 	justify-content: center;

		// 	& h4 {
		// 		font-size: $default-font-size * 4;
		// 	}
		// 	& p {
		// 		margin: $default-font-size;
		// 		font-size: $default-font-size * 1;
		// 		transition:
		// 			color $animation-duration * 1 $animation-easing-standard,
		// 		;
		// 	}

		// 	&:hover {
		// 		& p {
		// 			--welcome-opacity: 1;
		// 		}
		// 	}
		// }

		&-left {
			transform: translate(-100%, 0);
			grid-row: 2 / span 1;
			grid-column: 1 / span 2;
			text-align: left;
			justify-self: flex-start;
			padding-left: calc((100vw - #{$header-inner-width-vw}) / 2);

			& .home__main-link {
				animation: 10s text-bounce forwards $animation-easing-standard infinite,
					animate-skills-both infinite 10s;
			}

			@include respond(nav-switch) {
				padding-left: 0;
			}

			@include respond($breakpoint-to-change-layout) {
				grid-row: 3 / span 1;
				background-color: rgba(
					var(--color-primary-1-rgb),
					$home-background-color-opacity
				);
				border-top: $home-page-border;
				border-bottom: $home-page-border;
				border-right: $home-page-border;
			}
		}

		&-right {
			transform: translate(100%, 0);
			grid-row: 2 / span 1;
			grid-column: 2 / span 2;
			text-align: right;
			justify-self: flex-end;
			padding-right: calc((100vw - #{$header-inner-width-vw}) / 2);

			& .home__main-link {
				animation: 10s text-bounce forwards $animation-easing-standard infinite,
					animate-skills-both-reverse infinite 10s;
			}

			@include respond(nav-switch) {
				padding-right: 0;
			}

			@include respond($breakpoint-to-change-layout) {
				grid-row: 4 / span 1;
				background-color: rgba(
					var(--color-primary-2-rgb),
					$home-background-color-opacity
				);
			}
		}

		&-left,
		&-right {
			color: rgba(var(--color-primary-4-rgb), $home-regular-text-opacity);
			position: relative;
			z-index: 10000;
			display: flex;
			justify-content: center;
			align-items: center;

			@include respond($breakpoint-to-change-layout) {
				// width: 100vw;
			}
		}

		&-left-content,
		&-right-content {
			padding: $default-font-size * 0.5;
			align-self: center;
			margin: 0 auto;
			width: 66vw;

			@include respond($breakpoint-to-change-layout) {
				text-align: center;
				margin: 0;
				width: 100vw;
				padding-bottom: $default-font-size * 0.5;
				font-size: $font-size-four;
			}
		}

		--primary-4-end: 12.5%;
		// --background-image-color-two: hsl(7, 90%, 52%);
		// --background-image-color-two: hsl(15, 100%, 50%);
		// --background-image-color-two: rgb(255, 64, 60);
		// --background-image-color-two: rgb(243, 48, 82);
		--background-image-color-two: rgb(243, 98, 102);

		&-left {
			&-content {
				& .home__content-header {
					background-image: linear-gradient(
						-5deg,
						$home-content-header-gradient-colors
					);
				}

				@include respond($breakpoint-to-change-layout) {
					transform: none;
				}
			}
		}

		&-right {
			&-content {
				transform: translateY(10%);

				@include respond($breakpoint-to-change-layout) {
					transform: none;
				}

				& .home__content-header {
					background-image: linear-gradient(
						5deg,
						$home-content-header-gradient-colors
					);
				}
			}
		}

		&-bottom {
			font-weight: $home-name-font-weight;
			transform: translate(0, 100%);
			--bottom-color: rgba(
				var(--color-primary-4-rgb),
				#{$home-regular-text-opacity}
			);
			--bottom-left-color: rgba(var(--color-primary-4-rgb), 0.1);
			--bottom-middle-color-1: rgba(var(--color-primary-4-rgb), 0.85) 25%;
			--bottom-middle-color-2: rgba(var(--color-primary-4-rgb), 0.85) 75%;
			--bottom-right-color: rgba(var(--color-primary-4-rgb), 0.1);
			color: var(--bottom-color);
			grid-column: 1 / -1;
			grid-row: 4 / span 1;
			text-align: center;
			padding: $default-font-size * 0.5;
			// background-image: linear-gradient(
			// 	to right,
			// 	var(--bottom-left-color),
			// 	var(--bottom-middle-color-1),
			// 	var(--bottom-middle-color-2),
			// 	var(--bottom-right-color)
			// );
			background-color: $color-black;

			@include respond(nav-switch) {
				// background-color: rgba(var(--color-primary-4-rgb), 1);
				background-image: none;
			}

			@include respond($breakpoint-to-change-layout) {
				grid-row: 5 / span 1;
				border-top: $home-page-border;
			}

			&:hover {
				transition: color $animation-duration / 3 $animation-easing-standard;
				--bottom-color: rgba(var(--color-primary-4-rgb), 1);
			}
		}

		&-link {
			text-decoration: none;
			margin: 0;
			// font-size: $default-font-size * 1.5;
			font-weight: 700;
			font-size: $font-size-six;
			@include link-animating(
				var(--color-primary-3-rgb),
				var(--color-white-rgb),
				var(--color-primary-3-rgb),
				var(--color-primary-3-rgb),
				1,
				1,
				1,
				1
			);
			border: none;

			@include respond($breakpoint-to-change-layout) {
				animation: 10s text-bounce-mobile forwards $animation-easing-standard
						infinite,
					animate-skills infinite 10s;
					font-size: 2vh;
			}

			&:hover {
				animation: none;
				color: rgba(var(--color-primary-4-rgb), 1);
				letter-spacing: $default-font-size * $text-bounce-letter-spacing-factor;
			}
		}
	}

	&__content-header {
		// text-shadow: getTextShadow(
		// 	hard,
		// 	var(--color-primary-2-rgb),
		// 	var(--color-primary-4-rgb),
		// 	0.05
		// );
		text-shadow: none;
		font-weight: 700;
		font-family: $font-family-fancy-bridge;
		font-size: $font-size-nine;
		color: transparent;
		background-clip: text;

		@include respond($breakpoint-to-change-layout) {
			--background-image-color-two: var(--color-primary-3);
			font-size: $font-size-four;
		}
	}

	&__quote {
		font-size: $font-size-four;
		font-weight: 100;
		padding: $default-font-size * 2 $default-font-size * 4;
		transform: translateY(100%);
		grid-column: 2 / span 1;
		grid-row: 3 / -1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: rgba(var(--color-primary-4-rgb), 1);

		@include respond($breakpoint-to-change-layout) {
			opacity: 0;
			visibility: none;
		}

		&-author {
			text-align: center;
			font-family: $font-family-fancy-bridge;
		}

	}

	&__animation-ease-in-out-back {
		animation: $home-page-animation
			$animation-easing-standard-easeInOutBack-smaller;
	}

	&__animation-ease {
		animation: $home-page-animation
			$animation-easing-standard-easeInOutBack-smaller;

		@include respond($breakpoint-to-change-layout) {
			display: flex;
			justify-content: center;
			align-items: center;
			animation: $home-page-animation ease;
		}
	}
}
