/* global reset;  box-sizing is set on body and set to inherit on all other elements */
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}
html {
	/* 62.5% of the browser supplied font-size is usually 16px */
	/* bad practice to declarer px font-size here as it removes users ability to set font-size in browser */
	font-size: 62.5%;
	@include respond(big-desktop) {
		font-size: 75%;
	}
	@include respond(tab-land) {
		// font-size: 56.25%;
	}
	@include respond(tab-port) {
		// font-size: 43.75%;
		font-size: 56.25%;
	}
	// @include respond(phone) {
	//     font-size: 37.5%;
	// }
	@include respond(nav-break) {
		// font-size: 31.25%;
		font-size: 50%;
	}
	@include respond(small-font) {
		// font-size: 1.65vmin;
	}
	box-sizing: border-box;

	//order to add media queries:
	//base + typography > general + grid > page layout > components
}
/* everything related to font goes in body */
body {
	font-weight: 400;
	line-height: $line-height;
	font-family: $font-family-headers;
	color: var(--color-primary-1);
	overflow-x: hidden;
}

.body-background {
	background-image: var(--body-background);
}

.root {
	display: flex;
	// grid-template-rows: max-content min-content 40vw repeat(3, min-content);
	// grid-template-columns:
		// [sidebar-start] 8rem
		// [sidebar-end full-start] minmax(6rem, 1fr)
		// [center-start] repeat(8, [col-start] minmax(min-content, 14rem) [col-end])
		// [center-end] minmax(6rem, 1fr) [full-end grid-end];
}

//Style selected text
::selection {
	background-color: var(--color-primary-1);
	color: var(--color-primary-4);
}
