@keyframes translateToZero {
	0% {
	}
	99% {
	}
	100% {
		transform: translate(0, 0);
	}
}

@keyframes black-hole {
	0% {
		transform: scale(1) rotate(0deg);
	}
	100% {
		transform: scale(0) rotate(720deg);
	}
}

@keyframes black-hole-background {
	0% {
		background-color: var(--color-primary-1);
	}
	100% {
		background-color: $color-black;
	}
}


@keyframes square-even {
	0% {
		transform: var(--translate-center) rotate(0deg);
	}
	100% {
		transform: var(--translate-center) rotate(360deg);
	}
}

@keyframes square-odd {
	0% {
		transform: var(--translate-center) rotate(0deg);
	}
	100% {
		transform: var(--translate-center) rotate(-360deg);
	}
}

@keyframes bridge-hero-windshield {
	0%{ 
		clip-path: $animation-bridge-windshield-start;
	}
	50% {
		clip-path: $animation-bridge-windshield-end;
	}
	100%{ 
		clip-path: $animation-bridge-windshield-start;
	}
}

@keyframes bridge-hero-windshield-two {
	0%{ 
		clip-path: $animation-bridge-windshield-two-start;
	}
	50% {
		clip-path: $animation-bridge-windshield-two-end;
	}
	100%{ 
		clip-path: $animation-bridge-windshield-two-start;
	}
}

@keyframes bridge-more {
	0% {
		transform: translate(-50%, -50%) scale(1);
	}

	50% {
		transform: translate(-50%, -50%) scale(1.5);
	}

	100% {
		transform: translate(-50%, -50%) scale(1);
	}
}

$translate-centering: translate(-50%, -50%);
$starting-degrees: 0deg;
$max-degrees: 2.5deg;
@keyframes bridge-hero-hover {
	0%{
		transform: $translate-centering rotate($starting-degrees);
	}
	17%{
		transform: $translate-centering rotate($max-degrees);
	}
	33%{
		transform: $translate-centering rotate(-$max-degrees);
	}
	// 30%{
	// 	transform: $translate-centering rotate($max-degrees);
	// }
	50%{
		transform: $translate-centering rotate($max-degrees);
	}
	// 50%{
		// transform: $translate-centering rotate(2deg);
	// }
	60%{
		transform: $translate-centering rotate($starting-degrees);
	}
	85%{
		transform: $translate-centering rotate($max-degrees);
	}
	// 80%{
	// 	transform: $translate-centering rotate($max-degrees);
	// }
	// 90%{
	// 	transform: $translate-centering rotate($max-degrees);
	// }
	100%{
		transform: $translate-centering rotate($starting-degrees);
	}
}

@keyframes color-change {
	0% {
		opacity: 1;
		fill: var(--color-primary-1);
	}
	33% {
		fill: var(--color-primary-2);
	}
	66% {
		fill: var(--color-primary-4);
	}
	100% {
		fill: var(--color-primary-1);
		opacity: 1;
	}
}

$text-bounce-letter-spacing-factor: .2;
$text-bounce-margin-factor: .15;
@keyframes text-bounce {
	0%{ 
		letter-spacing: 0;
		// margin: 0;
	}
	// 10% {
		// letter-spacing: $default-font-size * .2;
		// margin: auto $default-font-size * .15;
	// }
	30% {
		letter-spacing: $default-font-size * $text-bounce-letter-spacing-factor;
		// margin: auto $default-font-size * $text-bounce-margin-factor;
	}
	90%{
		letter-spacing: 0;
		// margin: 0;
	}
	100%{ 
		letter-spacing: 0;
		// margin: 0;
	}
}

$text-bounce-mobile-smaller-factor: .4;
@keyframes text-bounce-mobile {
	0%{ 
		letter-spacing: 0;
		// margin: 0;
	}
	// 10% {
		// letter-spacing: $default-font-size * $text-bounce-letter-spacing-factor;
		// margin: auto $default-font-size * .15;
	// }
	30% {
		letter-spacing: $default-font-size * $text-bounce-letter-spacing-factor * $text-bounce-mobile-smaller-factor;
		// margin: auto $default-font-size * $text-bounce-margin-factor * $text-bounce-mobile-smaller-factor;
	}
	90%{
		letter-spacing: 0;
		// margin: 0;
	}
	100%{ 
		letter-spacing: 0;
		// margin: 0;
	}
}

@keyframes animate-skills {
	0% {
		background-position: 200% center;
	}
}

@keyframes animate-skills-both {
	0% {
		background-position: 200% center;
	}
	30% {
		background-position: 85% center;
	}
	100% {
		background-position: 275% center;
	}
}

@keyframes animate-skills-both-reverse {
	0% {
		background-position: -110% center;
	}
	30% {
		background-position: 0% center;
	}
	100% {
		background-position: -175% center;
	}
}

$ocean-sky-overlay-color: var(--color-black-rgb);
$ocean-sky-start-opacity: 0;
$ocean-sky-end-opacity: .05;
@keyframes ocean-sky-fading {
	0%{ 
		background-color: rgba($ocean-sky-overlay-color, $ocean-sky-start-opacity);

	}
	30% {
		background-color: rgba($ocean-sky-overlay-color, $ocean-sky-end-opacity);

	}
	90%{
		background-color: rgba($ocean-sky-overlay-color, $ocean-sky-start-opacity);

	}
	100%{ 
		background-color: rgba($ocean-sky-overlay-color, $ocean-sky-start-opacity);

	}
}

// @keyframes selected-section {
//   0% {
//     transform: scale(1.05) rotate(0);
//   }
//   16.667% {
//     transform: scale(1.11666667) rotate(1.25deg);

//   }
//   33% {
//     transform: scale(1.18336667) rotate(-1.25deg);
//   }
//   50% {
//     transform: scale(1.33) rotate(0);
//   }
//   100% {
//     transform: scale(1.05) rotate(0);
//   }
// }
