.csharp {
	font-size: $font-size-four;
	overflow: hidden;
	width: 100vw;
	background-color: var(--color-primary-1);
	transition: $header-transition;

	@include respond(nav-switch) {
		top: 0;
		left: 0;
		width: 100%;
		position: absolute;
		transform: translateY(var(--header-height));
	}

	// background-image:
	//   get-resume-card-gradient(.95)
	//   ,
	//   url($resume-card-background-work-history);
	// background-attachment: fixed;

	// @include respond(tab-port) {
	//   background-image: var(--color-primary-2);
	// }

	& .paragraph {
		margin: 0;
	}

	&__card {
		/* border: 3px solid rgba(var(--color-primary-4-rgb), 0.5); */
		border-radius: 0 0 $default-font-size / 2 $default-font-size / 2;
		box-shadow: $navbar-box-shadow-normal;
		width: $resume-card-width-full;

		background-color: rgba(var(--color-primary-4-rgb), 1);
		// color: rgba(var(--color-primary-1-rgb),.875);
		margin: 0 auto;

		@include respond(tab-land) {
			width: calc(100% - #{$default-font-size} * 1);
		}
	}

	&__content {
		z-index: 5;
		position: relative;
		& > *:not(.thumbnail-carousel__container, .thumbnail-carousel__item-viewer) {
			padding: 0 $default-font-size * 2 0 $default-font-size * 2;

			@include respond(phone) {
				padding: $default-font-size * 0.5 $default-font-size * 1;
			}
		}

		& > *:last-child() {
			padding-bottom: $default-font-size;

			@include respond(phone) {
				padding-bottom: $default-font-size * 0.5;
			}
		}
	}

	&__headers {
		--gradient: to bottom right, rgba(var(--color-primary-2-rgb), 1), rgba(var(--color-primary-1-rgb), 1);
		color: var(--color-primary-4);
		@include linear-gradient($gradient: var(--gradient));
	}

	&__header {
		display: grid;
    /* justify-content: flex-start; */
    /* align-items: center; */
    grid-template-columns: max-content 1fr;
    column-gap: $default-font-size;
    font-size: $font-size-eight;

		padding: $default-font-size * 1 $default-font-size * 1 $default-font-size *
			1 0;
		color: rgba(var(--color-primary-1), 1);
		border-bottom: $default-font-size * 0.15 solid
			rgba(var(--color-primary-1-rgb), 0.25);

		&-title {
			font-size: $font-size-eight;
			@include respond(tab-land) {
				// font-size: $font-size-seven;
			}
		}
	}

	&__card-section {
		&-title {
			color: rgba(var(--color-primary-1), 1);
			font-size: $font-size-five;
			padding: $default-font-size 0;
		}

		&:last-of-type {
			padding-bottom: $font-size-seven;
		}

		& {
			& ul {
				list-style: disc;
			}
			:is(ul, ol) {
				padding: 0 $default-font-size * 2 0 $default-font-size * 2;
			}
		}
	}

	&__section {
		margin: $default-font-size * 4 0;

		@include respond(tab-land) {
			margin-bottom: $default-font-size * 2;
		}
	}

	&__section-content {
		font-family: $font-family-text !important;

		& li {
			padding-left: $default-font-size * 0.5;
		}
	}

	&__title {
		--font-size: #{$font-size-nine};
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: text; /* Safari 'none' or 'text' here 'all' has issues*/
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

		font-size: var(--font-size);
		text-align: center;
		width: 100vw;
		text-shadow: $resume-header-text-shadow;
		color: var(--color-primary-4);

		display: flex;
		justify-content: center;
		align-items: center;

		&-link {
			text-decoration: none;
			transition: color $animation-duration * 0.5 $animation-easing-standard;
			@include link-animating(
				var(--color-primary-4-rgb),
				var(--color-primary-1-rgb),
				var(--color-primary-4-rgb),
				var(--color-primary-4-rgb)
			);
			&:hover {
				color: var(--color-primary-3);
			}
		}

		@include respond(nav-switch) {
			display: flex;
			flex-direction: column-reverse;
			width: $resume-card-width-full;
			margin: auto;
		}

		@include respond(tab-land) {
			--padding: #{$default-font-size * 0.5};
			width: 100vw;
			--font-size: #{$font-size-nine};
			/* padding: var(--padding) calc(var(--padding) + var(--scrollbar-width))
				var(--padding) var(--padding); */
		}
		@include respond(small-font) {
			--font-size: #{$font-size-nine};
			display: flex;
			// flex-direction: column;
			// margin-top: $default-font-size * 5;
		}
		@include respond(mobile) {
			position: static;
		}
	}

	&__carousel {
		// width: $resume-card-width-full;
		// margin: $default-font-size auto 0 auto;
		display: grid;
		row-gap: $default-font-size * 1;
		// grid-template-rows: $default-font-size * 23.75;
		grid-template-columns:
			1fr $resume-card-width-full / 3 $resume-card-width-full / 3
			$resume-card-width-full / 3 1fr;
		justify-content: center;

		@include respond(nav-switch) {
			width: calc(100% - #{$default-font-size} * 1);
			display: flex;
			flex-direction: row;
			padding-bottom: $default-font-size;
		}
	}
}

.downloader {
	background-color: rgba(var(--color-primary-2-rgb), 0.5);
	background-image: url($downloader-background-svg);
	background-size: cover;

	@include respond(tab-land) {
		background-image: linear-gradient(
			to right,
			var(--color-primary-1) 0%,
			var(--color-primary-2) 100%
		);
	}
}

.playlist-syncer {
	background-image: $bridge-card-section-2-gradient,
		url(getSvgBackground(
			bridge-section-2,
			$color-bridge-section-svg-1,
			$color-bridge-section-svg-1
		));
	background-size: cover;

	@include respond(tab-land) {
		background-image: linear-gradient(
			to right,
			var(--color-primary-1) 0%,
			var(--color-primary-2) 100%
		);
	}

	& .csharp__card {
		background-color: rgba(var(--color-primary-4-rgb), 1);
	}
}
