.bridge {
	display: grid;
	grid-template-rows: 100vh;
	// grid-auto-rows: 1fr;
	// grid-template-columns: repeat(8, 1fr);
	position: relative;
	width: 100%;
	background-color: var(--color-primary-4);

	// & .root.navbar--active {
	// 	filter: none;
	// }

	@include respond(nav-switch) {
		grid-template-rows: auto;
	}

	&__backdrop {
		background-color: transparent;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		visibility: hidden;
		z-index: 100;
	}

	&__subsection {
		&-grid {
			display: grid;
			grid-template-columns: min-content 1fr;
			// padding-bottom: $default-font-size * 1;
			align-items: center;
			justify-items: flex-start;
			row-gap: $default-font-size * 0.5;
			margin-bottom: $default-font-size * 1;

			& span:nth-of-type(2n + 1) {
				padding: 0 $default-font-size;
				align-self: flex-start;
			}
		}

		&-content {
			opacity: 0.95;
		}
	}

	&__section {
		backface-visibility: hidden;
		width: 100vw;
		height: var(--bridge-section-height);
		padding: var(--bridge-section-padding);
		z-index: 2;
		background-color: var(--color-primary-3);
		color: rgba($color-primary-bridge-1, $resume-color-opacity);
		transition: transform $animation-duration * 0.5 linear;

		@include respond(nav-switch) {
			height: auto;
			width: 100%;
			padding: $default-font-size * 2 0;
		}

		@include respond(phone) {
			padding: $default-font-size * 2 0;
		}

		@include respond(small-font) {
			padding: $default-font-size * 1 0;
		}

		&-title {
			text-align: center;
			@include respond(small-font) {
				font-size: $font-size-nine;
			}
		}

		&-container {
			display: flex;
			flex-direction: row;
			transition: transform $animation-duration ease;

			@include respond(nav-switch) {
				flex-direction: column;
			}
		}

		&-titles {
			// font-family: $font-family-fancy-bridge;
			padding: $default-font-size * 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@include respond(nav-switch) {
				// align-items: flex-end;
			}
		}

		&-content {
			position: relative;
			overflow-y: auto;
			padding: $default-font-size * 2 $default-font-size * 4 $default-font-size *
				0 $default-font-size * 4;
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			// align-items: center;

			@include respond(nav-switch) {
				overflow: visible;
			}

			@include respond(phone) {
				padding: $default-font-size * 1 $default-font-size * 2;
			}

			@include respond(small-font) {
				padding: $default-font-size * 1;
			}
		}

		&:nth-of-type(1) {
			// left: 0;
			background-image: $bridge-card-section-1-gradient;
				// url(getSvgBackground(
				// 	bridge-section-1,
				// 	$color-bridge-section-svg-1,
				// 	$color-primary-bridge-2
				// ));
			@include respond(tab-land) {
				background-image: $bridge-card-section-1-gradient;
			}

			& .bridge__section-titles {
				color: var(--color-primary-4);
				position: relative;

				&::before {
					content: "";
					background-image: linear-gradient(
							to right,
							rgba(var(--color-primary-1-rgb), 0.65),
							rgba(var(--color-primary-2-rgb), 0.65)
						),
						url("/src/imgs/bridge-section-3.jpg");
					background-position: top;
					background-size: cover;
					@include bridge-title-pics(0, 5px, 0.33);
				}
				@include bridge-title-pics-hover(center, 0.33, 0);
			}

			& .bridge__section-content {
				background-image: linear-gradient(
					var(--bridge-gradient-direction),
					rgba(var(--color-primary-3-rgb), 0.5) 0%,
					rgba(var(--color-primary-4-rgb), 0.5) 25%,
					rgba(var(--color-primary-4-rgb), 0.5) 75%,
					rgba(var(--color-primary-3-rgb), 0.5) 100%
				);
			}
		}

		&:nth-of-type(2) {
			background-image: $bridge-card-section-2-gradient;
				// url(getSvgBackground(
				// 	bridge-section-2,
				// 	$color-bridge-section-svg-1,
				// 	$color-bridge-section-svg-1
				// ));
			background-size: cover;

			@include respond(tab-land) {
				background-image: $bridge-card-section-2-gradient;
			}

			& .bridge__section-titles {
				color: var(--color-primary-1);
				position: relative;
				&::before {
					content: "";
					background-image: linear-gradient(
							to left,
							rgba(var(--color-primary-2-rgb), 0.875) 0%,
							rgba(var(--color-primary-3-rgb), 0.875) 100%
						),
						url("/src/imgs/bridge-section-2.jpg");
					background-position: center;
					background-size: cover;
					@include bridge-title-pics;
				}
				@include bridge-title-pics-hover(top);
			}

			& .bridge__section-content {
				padding: $default-font-size;
				overflow: hidden;
				display: grid;
				grid-template-columns: repeat(
					auto-fill,
					minmax($default-font-size * 9, 1fr)
				);
				align-items: center;
				color: var(--color-primary-4);
				background-image: linear-gradient(
					to right,
					rgba(var(--color-primary-3-rgb), 0.475),
					rgba(var(--color-primary-1-rgb), 0.475)
				);
			}

			& .bridge__card {
				border: $bridge-card-border-size var(--color-primary-2);
				box-shadow: $bridge-card-box-shadow-size
					rgba($color-primary-bridge-1, 0.5);

				@include respond(nav-switch) {
					min-height: calc(100vh - var(--header-height));
				}
			}
		}

		&:nth-of-type(3) {
			background-image: $bridge-card-section-3-gradient;
			// url(getSvgBackground(
			// 	bridge-section-1,
			// 	$color-bridge-section-svg-1,
			// 	$color-primary-bridge-2
			// ));

			@include respond(nav-switch) {
				background-image: $bridge-card-section-3-gradient;
			}

			& .bridge__section-titles {
				position: relative;
				color: var(--color-primary-1);

				&::before {
					content: "";
					background-image: linear-gradient(
							to right,
							rgba(var(--color-primary-2-rgb), 0.4625),
							rgba(var(--color-primary-3-rgb), 0.925) 33%,
							rgba(var(--color-primary-4-rgb), 0.4625) 66%,
							rgba(var(--color-primary-1-rgb), 0.925)
						),
						url("/src/imgs/bridge-section-1.jpg");
					background-position: top;
					background-size: cover;
					@include bridge-title-pics;
				}

				@include bridge-title-pics-hover(top);
			}

			& .bridge__section-content {
				background-image: linear-gradient(
					var(--bridge-gradient-direction),
					rgba(var(--color-primary-3-rgb), 0.475) 0%,
					rgba(var(--color-primary-3-rgb), 0.475) 25%,
					rgba(var(--color-primary-4-rgb), 0.475) 75%,
					rgba(var(--color-primary-4-rgb), 0.475) 100%
				);
			}
		}

		&:nth-of-type(4) {
			background-image: $bridge-card-section-1-gradient;
				// url(getSvgBackground(
				// 	bridge-section-3,
				// 	$color-bridge-section-svg-3,
				// 	$color-primary-bridge-1
				// ));
			
			@include respond(tab-land) {
				background-image: $bridge-card-section-1-gradient;
			}

			& .bridge__section-titles {
				color: var(--color-primary-4);
				position: relative;

				&::before {
					content: "";
					background-image: linear-gradient(
							to right,
							rgba(var(--color-primary-1-rgb), 0.65),
							rgba(var(--color-primary-2-rgb), 0.65)
						),
						url("/src/imgs/bridge-section-4.jpg");
					background-position: top;
					background-size: cover;
					@include bridge-title-pics(0, 5px, 0.33);
				}
				@include bridge-title-pics-hover(center, 0.33, 0);
			}

			& .bridge__section-content {
				background-image: linear-gradient(
					var(--bridge-gradient-direction),
					rgba(var(--color-primary-3-rgb), 0.5) 0%,
					rgba(var(--color-primary-4-rgb), 0.5) 25%,
					rgba(var(--color-primary-4-rgb), 0.5) 75%,
					rgba(var(--color-primary-3-rgb), 0.5) 100%
				);
			}
		}
	}

	&__card {
		font-size: $font-size-four;
		overflow: hidden;
		border: $bridge-card-border-size var(--color-primary-4);
		box-shadow: $bridge-card-box-shadow-size rgba($color-primary-bridge-4, 0.5);
		max-width: $resume-card-width-full;
		margin: 0 auto;
		// margin-right: calc(((100% - #{$resume-card-width-full}) / 2) + ((100vw - 100%) / 6));;
		height: 100%;
		border-radius: $default-font-size * 2;
		display: grid;
		grid-template-rows: min-content 1fr;

		@include respond(nav-switch) {
			margin: 0 auto;
			width: 97.5%;
			// overflow: auto;
			height: auto;
		}

		& div {
			// width: 100%;
		}
		& p,
		& h3 {
			margin-bottom: $default-font-size;

			@include respond(phone) {
				margin-bottom: $default-font-size * 0.75;
			}
		}
	}

	&__reasons {
		&-item {
		}
	}

	&__link {
		@include link-animating;
		@include link-line;

		&:hover {
			@include link-hover;
		}
	}
	&__page-nav-link {
		// @include link-animating;
		cursor: pointer;
		width: auto;
		opacity: 0.75;
		color: var(--bridge-page-nav-link-color);
		transition: color $animation-duration / 2 $animation-easing-standard,
			transform $animation-duration / 2 $animation-easing-standard;
		&:hover {
			opacity: 1;
			color: var(--bridge-page-nav-link-color-hover);
			transform: translateY(-$default-font-size * 0.1)
				scale($bridge-page-nav-link-scale-amount);
		}

		@include respond(nav-switch) {
			opacity: 1;
		}
	}
	&__current-section {
		opacity: 1;
		transform: scale($bridge-page-nav-link-scale-amount);

		&:hover {
			// pointer-events: none;
			cursor: auto;
			color: var(--bridge-page-nav-link-color);
			transform: scale($bridge-page-nav-link-scale-amount) translate(0, 0);
		}
	}

	//fixing unique bridge situation regarding no scroll on full screen
	.hero {
		&.bridge {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: text; /* Safari 'none' or 'text' here 'all' has issues*/
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
		}

		& .bg-video {
			width: 100vw;
		}

		& ~ .source-link__source {
			right: calc((100vw - #{$header-inner-width}) / 2);
		}

		&--clicked ~ .source-link__source {
			right: calc(
				(
						100vw -
							calc(
								#{$resume-card-width-full} + (
										(100vw - #{$resume-card-width-full}) / 2
									) + #{$navbar__logo-width} + 2vw
							)
					) / 2
			);
		}
	}

	@include respond(larger-than-nav-switch) {
		&-page .header {
			width: 100vw;

			&__inner {
				width: $header-inner-width-vw;
			}
		}
	}
}

.hero {
	background-image: linear-gradient(
		var(--bridge-gradient-direction),
		rgba($color-primary-bridge-2, $hero-background-image-alpha) 0%,
		rgba($color-primary-bridge-1, $hero-background-image-alpha) 25%,
		rgba($color-primary-bridge-1, $hero-background-image-alpha) 75%,
		rgba($color-primary-bridge-2, $hero-background-image-alpha) 100%
	);
	position: relative;
	// height: 85vh;

	@include respond(nav-switch) {
		height: 100vh;
	}

	&__heading {
		font-family: $font-family-fancy-bridge;
		// font-family: 'Spectral', serif;
		opacity: 0;
		visibility: hidden;
		mix-blend-mode: $hero-blend-mode;
		// z-index: 10;
		color: var(--color-primary-3);
		position: fixed;
		font-weight: 100;
		font-size: $font-size-twelve;

		transition: 
			opacity $animation-duration $animation-easing-standard,
			transform $animation-duration $animation-easing-standard-easeInOutBack,
			top $animation-duration $animation-easing-standard,
			
			// top $animation-duration $animation-easing-standard-easeInOutBack $animation-duration,
			// bottom $animation-duration $animation-easing-standard-easeInOutBack $animation-duration,
			// left $animation-duration $animation-easing-standard-easeInOutBack $animation-duration,
			// right $animation-duration $animation-easing-standard-easeInOutBack $animation-duration,
;
		&:first-of-type,
		&:nth-of-type(4) {
			font-size: $font-size-fourteen;
			font-weight: 200;

			@include respond(nav-break) {
				font-size: $font-size-twelve;
			}
		}

		&:nth-of-type(2),
		&:nth-of-type(3) {
			font-size: $font-size-eleven;
			font-weight: 700;

			@include respond(small-font) {
				font-size: $font-size-ten;
			}
		}

		&:first-of-type {
			top: calc(23vh + var(--header-height) / 2);
			left: 50vw;
		}

		&:nth-of-type(2) {
			mix-blend-mode: $hero-blend-mode;
			top: 50vh;
			left: 25vw;

			@include respond(small-font) {
				left: 50vw;
				top: calc(36vh + var(--header-height) / 2);
			}
		}
		&:nth-of-type(3) {
			mix-blend-mode: $hero-blend-mode;
			top: 50vh;
			left: 75vw;

			@include respond(small-font) {
				left: 50vw;
				top: calc(62.5vh + var(--header-height) / 2);
			}
		}
		&:nth-of-type(4) {
			mix-blend-mode: $hero-blend-mode;
			top: calc(75vh + var(--header-height) / 2);
			left: 50vw;
		}
	}

	&__more {
		display: flex;
		align-items: center;
		justify-content: center;
		// font-size: $font-size-nine;
		z-index: 1;
		position: fixed;
		top: calc(50vh + var(--header-height) /2);
		left: 49vw;
		transform: translate(-50%, -50%);
		color: var(--color-primary-2);
		border-radius: $default-font-size * 200;
		animation: bridge-more 1s infinite alternate-reverse;
		transition: top $animation-duration $animation-easing-standard;

		& input {
			display: none;
		}

		& label {
			margin: 0;
			padding: 0;
			height: 100%;
			cursor: pointer;
			display: flex;
			justify-content: center;
		}

		& svg {
			width: $navbar__logo-width * 1;
			height: $navbar__logo-width * 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: 
				transform $animation-duration + $bridge-hero-animation-duration / 2 $animation-easing-standard,
				opacity $animation-duration + $bridge-hero-animation-duration / 2 $animation-easing-standard,
				fill $animation-duration $animation-easing-standard;
			fill: var(--color-primary-2);

			@include respond(nav-switch) {
				width: $navbar__logo-width-nav-switch * 1;
				height: $navbar__logo-width-nav-switch * 1;
			}

			&:nth-of-type(1) {
				opacity: 1;
			}

			&:nth-of-type(2) {
				opacity: 0;
			}
		}

		&--clicked {
			--hero-more-y-translate: calc(-50% - #{$default-font-size * 7});

			@include respond(phone) {
				--hero-more-y-translate: calc(-50% - #{$default-font-size * 8});
			}

			@include respond(nav-break) {
				--hero-more-y-translate: calc(-50% - #{$default-font-size * 9});
			}

			& svg:nth-child(2) {
				animation: color-change 10s infinite;
			}
		}

		&:hover {
			& svg {
				fill: var(--color-primary-4);
				animation: none;
				z-index: 1;
			}

			& .hero__bridge-logo {
				opacity: $bridge-logo-opacity;
				background-color: var(--color-primary-1);
			}
		}
	}

	&__bridge-logo {
		pointer-events: none;
		background-image: url("../imgs/bridge-logo.svg");
		position: fixed;
		top: 50%;
		left: 50%;
		height: calc(#{$navbar__logo-width} * 2 - 0.5vw);
		width: calc(#{$navbar__logo-width} * 2 - 0.5vw);
		border-radius: 50%;
		transform: translate(-50%, -50%);
		background-size: cover;
		opacity: 0;
		transition: opacity $animation-duration $animation-easing-standard;

		@include respond(nav-switch) {
			height: calc(#{$navbar__logo-width-nav-switch} * 2 - 0.5rem);
			width: calc(#{$navbar__logo-width-nav-switch} * 2 - 0.5rem);
		}
		// z-index: 1;
	}

	&__background {
		opacity: 0;
		background-image: linear-gradient(
				to bottom,
				rgba($color-primary-bridge-2, $hero-background-opacity),
				rgba($color-primary-bridge-1, 1)
			),
			url("/src/imgs/bridge-background.jpg");
		position: fixed;
		width: 103vw;
		height: 140vh;
		background-position: center;
		background-size: cover;
		transition: opacity $animation-duration $animation-easing-standard;
	}

	&.d-none ~ &__source {
		& svg {
			opacity: 0.33;

			// &:hover ~ .hero__source-label {
			// 	color: var(--bridge-page-nav-link-color);
			// }
		}
		& span {
			font-weight: 1000;
			color: var(--bridge-page-nav-link-color);
		}
	}
}

#hero__more-checkbox {
	display: none;

	&:checked ~ .hero svg:not(.hero__source-svg) {
		&:first-of-type {
			opacity: 0;
		}

		&:nth-of-type(2) {
			opacity: 1;
		}
	}

	&:checked ~ .hero .hero__bridge-logo {
		opacity: 0;
		z-index: 0;
	}

	&:checked ~ .hero .hero__more {
		// opacity: 0;
		// visibility: hidden;
		left: 50%;
	}

	&:checked ~ .hero .bg-video {
		clip-path: $animation-bridge-windshield-two-end;
	}

	&:checked ~ .hero .hero__heading {
		opacity: 1;
		visibility: visible;
		transform: translate(-50%, -50%);

		&:first-of-type {
			animation: bridge-hero-hover 10s infinite;
		}
		&:nth-of-type(2) {
			animation: bridge-hero-hover 10s infinite;
		}
		&:nth-of-type(3) {
			animation: bridge-hero-hover 10s infinite;
		}
		&:nth-of-type(4) {
			animation: bridge-hero-hover 10s infinite;
		}
	}

	&:checked ~ .hero .diamond-fractal {
		left: 0;
		opacity: 0.05;
	}
}

$padding-full: $default-font-size * 1.5;
$padding-nav-switch: $default-font-size * 0.5;
.arrow-button {
	cursor: pointer;
	position: absolute;
	bottom: $header-top;
	z-index: 10000;
	border-radius: 50%;
	width: calc(
		(#{$navbar__logo-width} + 0.29vw) / var(--bridge-arrow-hover-scale-amount)
	);
	height: calc(
		(#{$navbar__logo-width} + 0.29vw) / var(--bridge-arrow-hover-scale-amount)
	);
	transition: transform $animation-duration * 0.5 $animation-easing-standard,
		opacity $animation-duration * 1.5 $animation-easing-standard;

	@include respond(nav-switch) {
		display: none;
	}

	& svg {
		width: 100%;
		height: 100%;
		transition: transform $animation-duration $animation-easing-standard;
		@include respond(nav-switch) {
			width: $default-font-size * 5;
			height: $default-font-size * 5;
		}
	}

	&--left {
		// padding-left: $padding-full;
		// left: 0%;
		left: calc((100vw - #{$header-inner-width}) / 2);
		// border: $default-font-size * .1 solid var(--bridge-arrow-button-left-fill);

		@include respond(nav-switch) {
			padding-left: $padding-nav-switch;
		}

		& svg {
			fill: var(--bridge-arrow-button-left-fill);
			// transform: translateY(.05vw) rotate(90deg);
			transform: rotate(var(--bridge-arrow-button-rotation))
				scale(var(--bridge-arrow-hover-scale-amount));
		}

		&:hover svg {
			fill: var(--bridge-arrow-button-left-fill-hover);
		}
	}

	&--right {
		right: $arrow-button-position;
		// border: $default-font-size * .1 solid var(--bridge-arrow-button-right-fill);

		@include respond(nav-switch) {
			padding-right: $padding-nav-switch;
		}

		& svg {
			fill: var(--bridge-arrow-button-right-fill);
			// transform: translateX(-.025vw) rotate(-90deg);
			transform: rotate(calc(var(--bridge-arrow-button-rotation) * -1))
				scale(var(--bridge-arrow-hover-scale-amount));
		}

		&:hover svg {
			fill: var(--bridge-arrow-button-right-fill-hover);
			// transform: translate(-$default-font-size * .15, ) rotate(-90deg);
		}
	}

	&:hover {
		transform: translateY(-$default-font-size / 4);
		box-shadow: $navbar-box-shadow-resume;
	}

	// &:hover {
	// 	fill: var(--bridge-arrow-button-fill-hover);
	// }

	// &__fill-1 {
	// 	fill: var(--color-primary-bridge-1);
	// }
	// &__fill-2 {
	// 	fill: var(--color-primary-bridge-2);
	// }
	// &__fill-3 {
	// 	fill: var(--color-primary-bridge-3);
	// }
	// &__fill-4 {
	// 	fill: var(--color-primary-bridge-4);
	// }

	// &__hover-fill-1:hover {
	// 	fill: var(--color-primary-bridge-1);
	// }
	// &__hover-fill-2:hover {
	// 		fill: var(--color-primary-bridge-2);
	// 	}
	// &__hover-fill-3:hover {
	// 		fill: var(--color-primary-bridge-3);
	// 	}
	// &__hover-fill-4:hover {
	// 		fill: var(--color-primary-bridge-4);
	// 	}
}

.reverse-ease {
	transition: opacity $animation-duration cubic-bezier(0.4, 0, 1, 1)
		$animation-duration * 1.25;
}

.page-nav-bridge {
}

// .bridge-page,
// .csharp-page {
// 	//Changing header sizes
// 	@include respond(larger-than-nav-switch) {
// 		& .header {
// 			top: $default-font-size / 2;

// 			&__inner {
// 				// width: calc(100% - #{$default-font-size * 3});
// 			}
// 		}

// 		& .site-nav,
// 		& .github {
// 			transform: scale(0.75);
// 			transition: transform $animation-duration $animation-easing-standard
// 				$animation-duration * 0.75;
// 		}

// 		& .site-nav {
// 			transform-origin: left;
// 		}

// 		& .github {
// 			transform-origin: right;
// 		}

// 		& .github:hover,
// 		& .site-nav:hover {
// 			transform: scale(1);
// 		}
// 	}
// }


.bridge-demo {
	background-image: url($bridge-demo-svg-background);
}